import Papa from 'papaparse';

import api from 'services/ether';
import { ExportFormat } from '../components/ExportGroup';

import { IModel } from '..';
import { DataTableFilterMetaData } from 'primereact/datatable';
import { getDateISO, getDateRange } from 'utils/dateUtils';
import downloadFromUrl from 'utils/downloadFromUrl';

export enum ExportMode {
    EXPORT_ALL,
    EXPORT_LIMIT,
    EXPORT_PAGE,
}

interface IExport {
    model: IModel;
    filters?: {
        [key: string]: {
            value: unknown;
            filterType: DataTableFilterMetaData['matchMode'];
        };
    };
    onDownloadStart(): void;
    onDownloadEnd(): void;
}

interface IFetchRequest {
    token?: string;
    filters?: {
        [key: string]: {
            value: unknown;
            filterType: DataTableFilterMetaData['matchMode'];
        };
    };
    page: number;
    limit: number;
}

export const applyApiFilters = (filters: {
    [key: string]: {
        value: unknown;
        filterType: DataTableFilterMetaData['matchMode'];
    };
}) => {
    const apiFilters: { [key: string]: string } = {};
    Object.entries(filters).forEach((item) => {
        switch (item[1].filterType) {
            case 'startsWith':
                apiFilters[item[0] + '|regex'] = '^' + item[1].value;
                break;
            case 'contains':
                apiFilters[item[0] + '|regex'] = `${item[1].value}`;
                break;
            case 'notContains':
                apiFilters[item[0] + '|regex'] = `^(?!.*${item[1].value}).*$`;
                break;
            case 'endsWith':
                apiFilters[item[0] + '|regex'] = item[1].value + '$';
                break;
            case 'equals':
                apiFilters[item[0]] = `${item[1].value}`;
                break;
            case 'notEquals':
                apiFilters[item[0] + '|ne'] = `${item[1].value}`;
                break;
            case 'lt':
                apiFilters[item[0] + '|lt'] = `${item[1].value}`;
                break;
            case 'lte':
                apiFilters[item[0] + '|lte'] = `${item[1].value}`;
                break;
            case 'gt':
                apiFilters[item[0] + '|gt'] = `${item[1].value}`;
                break;
            case 'gte':
                apiFilters[item[0] + '|gte'] = `${item[1].value}`;
                break;
            case 'dateIs': {
                const date = item[1].value as Date;
                apiFilters[item[0] + '|range'] = getDateRange(date);
                break;
            }
            case 'dateIsNot': {
                const date = item[1].value as Date;
                apiFilters[item[0] + '|not,range'] = getDateRange(date);
                break;
            }
            case 'dateBefore': {
                const date = item[1].value as Date;
                apiFilters[item[0] + '|lt'] = `${getDateISO(date)}`;
                break;
            }
            case 'dateAfter': {
                const date = item[1].value as Date;
                apiFilters[item[0] + '|gt'] = `${getDateISO(
                    date
                )}T23:59:59.999999`;
                break;
            }
        }
    });
    return apiFilters;
};

const fetchModelData = (
    url: string,
    { token, filters, page = 1, limit = 50 }: IFetchRequest
) => {
    return new Promise<{
        payload: {
            [key: string]: unknown;
        }[];
        payloadCount: number;
    }>((resolve, reject) => {
        const apiFilters = filters ? applyApiFilters(filters) : {};
        api.get(url, {
            headers: {
                ...(token && { 'access-token': token }),
            },
            params: {
                limit: limit,
                offset: (page - 1) * limit,
                ...apiFilters,
            },
        })
            .then((res) => {
                resolve({
                    payload: res ? res.data.payload : [],
                    payloadCount: res ? res.data.meta['payload_count'] : 0,
                });
            })
            .catch((err) => reject(err));
    });
};

export const exportFile = async (
    url: string,
    exportMode: ExportMode,
    exportFormat: ExportFormat,
    { model, filters = {}, onDownloadStart, onDownloadEnd }: IExport,
    exportOptions: {
        totalLimit?: number;
        pageToExport?: { page: number; limit: number };
    } = {
        totalLimit: 0,
        pageToExport: { page: 1, limit: 50 },
    },
    setExported: (amt: number) => void = (amt: number) => {
        return amt;
    }
) => {
    let totalLimit = exportOptions.totalLimit ? exportOptions.totalLimit : 100;
    const pageToExport = exportOptions.pageToExport
        ? exportOptions.pageToExport
        : { page: 1, limit: 50 };

    const modelFields = model.fields.filter(
        (field) => field.key !== model.id_field && field.show
    );

    const jsonData: string[] = [];
    let csvHeader: string[] = [];
    const csvData: string[][] = [];

    if (exportFormat === ExportFormat.CSV) {
        csvHeader = csvHeader.concat(model.id_field);
        csvHeader = csvHeader.concat(modelFields.map((field) => field.key));
    }

    const requestRowLimit = 500;
    let exported = 0;
    try {
        let continueRequests = true;
        let requestsMade = 0;
        let page = 1;

        onDownloadStart();
        while (continueRequests && requestsMade < 3000) {
            const fetchDetails = {
                limit: requestRowLimit,
                page,
                filters,
            };
            if (exportMode === ExportMode.EXPORT_LIMIT) {
                if (requestRowLimit >= totalLimit) {
                    fetchDetails.limit = totalLimit;
                    continueRequests = false;
                }
                totalLimit -= requestRowLimit;
            } else if (exportMode === ExportMode.EXPORT_PAGE) {
                fetchDetails.page = pageToExport.page;
                fetchDetails.limit = pageToExport.limit;
                continueRequests = false;
            }

            const { payload, payloadCount } = await fetchModelData(
                url,
                fetchDetails
            );
            requestsMade += 1;
            exported += payloadCount;
            setExported(exported);
            if (payloadCount > 0) {
                if (exportFormat === ExportFormat.CSV) {
                    payload.forEach((rowData) => {
                        const data: string[] = [];
                        data.push(rowData[model.id_field] as string);
                        modelFields.forEach((field) => {
                            const value = rowData[field.key];
                            if (
                                field.type == null ||
                                field.type === 'dict' ||
                                field.type === 'list'
                            )
                                data.push(JSON.stringify(value));
                            else data.push(value as string);
                        });
                        csvData.push(data);
                    });
                } else {
                    payload.forEach((rowData) => {
                        jsonData.push(JSON.stringify(rowData));
                    });
                }
            }
            if (requestRowLimit > payloadCount) continueRequests = false;
            page += 1;
        }
        let blob: Blob;
        const format = exportFormat === ExportFormat.CSV ? '.csv' : '.json';
        if (exportFormat === ExportFormat.CSV) {
            const csv = Papa.unparse({
                fields: csvHeader,
                data: csvData,
            });
            blob = new Blob([csv]);
        } else {
            blob = new Blob([jsonData.join('\n')]);
        }
        const fileDownloadUrl = URL.createObjectURL(blob);
        downloadFromUrl(fileDownloadUrl, new Date().toISOString(), format);
        URL.revokeObjectURL(fileDownloadUrl);
    } catch (err) {
        console.error(err);
    } finally {
        onDownloadEnd();
    }
};
