import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from 'hooks/useAuth';
import Routes from '.';

const Routing = () => {
    return (
        <BrowserRouter basename={window.ROUTE_BASENAME}>
            <AuthProvider>
                <Routes />
            </AuthProvider>
        </BrowserRouter>
    );
};

export default Routing;
