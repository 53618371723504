import api from 'services/ether';
import { Ether } from 'types';

export const listGroups = (options?: {
    signal?: AbortSignal;
}): Promise<Ether.IGroup[]> => {
    return new Promise<Ether.IGroup[]>((resolve, reject) => {
        api.get<Ether.IApiResponse<Ether.IGroup[]>>('list-groups', {
            signal: options?.signal,
            params: {
                limit: 10000,
            },
        })
            .then((res) => resolve(res.data.payload))
            .catch(reject);
    });
};
