import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { InputText, InputTextArea } from 'components/ethercity-primereact';
import { IModel } from '../..';
import { apiModelProxyCreate } from 'services/ether/backoffice/api-model';

interface IRowDataProps extends DialogProps {
    apiSlug: string;
    modelFormat: IModel;
    accessToken?: string;
    onCreate?(): void;
}

interface IRowData {
    [key: string]: { label: string; type: string | null; value: unknown };
}

const CreateDataDialog: React.FC<IRowDataProps> = ({
    apiSlug,
    modelFormat,
    style,
    accessToken,
    onCreate,
    ...rest
}) => {
    const toastRef = useRef<Toast>(null);
    const [rowFields, setRowFields] = useState<IRowData>({});

    const setInitialValue = (type: string | null): unknown => {
        switch (type) {
            case 'bool':
                return null;
            case 'datetime':
                return null;
            default:
                return '';
        }
    };

    const clearData = () => {
        setRowFields((old) => {
            const newRowData: IRowData = {
                ...old,
            };
            Object.keys(old).forEach((k) => {
                newRowData[k].value = setInitialValue(newRowData[k].type);
            });
            return newRowData;
        });
    };

    const handleOnHide = () => {
        // clearData();
        rest.onHide();
    };

    const createMutate = useMutation<string, Error, { [key: string]: unknown }>(
        (createData) =>
            apiModelProxyCreate(apiSlug, modelFormat.name, createData),
        {
            onSuccess: (res) => {
                toastRef.current?.show({
                    severity: 'success',
                    summary: `New entry OID: ${res}`,
                    life: 10000,
                });
                onCreate && onCreate();
                clearData();
                rest.onHide();
            },
            onError: (err) => {
                toastRef.current?.show({
                    severity: 'error',
                    summary: 'Error when creating',
                    detail: err.message,
                });
            },
        }
    );

    const handleCreate = () => {
        const dataObject: { [key: string]: unknown } = {};
        Object.keys(rowFields).forEach((key) => {
            // const type = rowFields[key].type;
            let value = rowFields[key].value;
            if (typeof value === 'string' && value === '') value = null;
            else {
                try {
                    value = JSON.parse(value as string);
                } catch {}
            }
            dataObject[key] = value;
        });
        createMutate.mutate(dataObject);
    };

    const getFieldInput = (
        fieldName: string,
        type: string | null,
        value: unknown
    ): React.ReactElement => {
        const isJson = (value: string | undefined) => {
            if (!value) return true;
            try {
                JSON.parse(value);
            } catch (e) {
                return value === '';
            }
            return true;
        };

        const isOid = (value: string | undefined) => {
            if (!value) return true;
            const testCase = /[a-f0-9]{24}/;
            return testCase.test(value);
        };

        switch (type) {
            case null:
                return (
                    <InputTextArea
                        value={value as string}
                        onChange={(e) => {
                            setRowFields((old) => ({
                                ...old,
                                [fieldName]: {
                                    label: fieldName,
                                    type: type,
                                    value: e.target.value,
                                },
                            }));
                        }}
                    />
                );
            case 'dict':
                return (
                    <InputTextArea
                        value={value as string}
                        onChange={(e) => {
                            setRowFields((old) => ({
                                ...old,
                                [fieldName]: {
                                    label: fieldName,
                                    type: type,
                                    value: e.target.value,
                                },
                            }));
                        }}
                        validations={[
                            {
                                validate: isJson,
                                validationError: 'Must be a valid JSON object',
                            },
                        ]}
                    />
                );
            case 'list':
                return (
                    <InputTextArea
                        value={value as string}
                        onChange={(e) => {
                            setRowFields((old) => ({
                                ...old,
                                [fieldName]: {
                                    label: fieldName,
                                    type: type,
                                    value: e.target.value,
                                },
                            }));
                        }}
                    />
                );
            case 'datetime':
                return (
                    <Calendar
                        hideOnDateTimeSelect
                        value={value as Date}
                        onChange={(e) => {
                            setRowFields((old) => ({
                                ...old,
                                [fieldName]: {
                                    label: fieldName,
                                    type: type,
                                    value: e.target.value,
                                },
                            }));
                        }}
                        showTime
                        showSeconds
                    />
                );
            case 'bool':
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TriStateCheckbox
                            value={value as boolean}
                            onChange={(e) => {
                                setRowFields((old) => ({
                                    ...old,
                                    [fieldName]: {
                                        label: fieldName,
                                        type: type,
                                        value: e.target.value,
                                    },
                                }));
                            }}
                        />
                        <span style={{ marginLeft: '8px' }}>
                            <Badge
                                value={
                                    value
                                        ? 'true'
                                        : value == null
                                        ? 'null'
                                        : 'false'
                                }
                                severity={
                                    value
                                        ? 'success'
                                        : value != null
                                        ? 'danger'
                                        : undefined
                                }
                            />
                        </span>
                    </div>
                );
            case 'oid':
                return (
                    <InputText
                        value={value as string}
                        onChange={(e) => {
                            setRowFields((old) => ({
                                ...old,
                                [fieldName]: {
                                    label: fieldName,
                                    type: type,
                                    value: e.target.value,
                                },
                            }));
                        }}
                        validations={[
                            {
                                validate: isOid,
                                validationError: 'Invalid OID',
                            },
                        ]}
                    />
                );
            default:
                return (
                    <InputText
                        value={value as string}
                        onChange={(e) => {
                            setRowFields((old) => ({
                                ...old,
                                [fieldName]: {
                                    label: fieldName,
                                    type: type,
                                    value: e.target.value,
                                },
                            }));
                        }}
                    />
                );
        }
    };

    useEffect(() => {
        if (!modelFormat) return;
        const rowFields: {
            [key: string]: { label: string; type: string; value: unknown };
        } = {};
        modelFormat.fields.forEach((field) => {
            if (field.key === modelFormat.id_field || !field.show) return;
            if (
                ['created_at', 'updated_at'].findIndex(
                    (x) => field.label === x
                ) !== -1
            )
                return;
            rowFields[field.label] = {
                label: field.label,
                type: field.type,
                value: setInitialValue(field.type),
            };
        });
        setRowFields(rowFields);
    }, [modelFormat]);

    return (
        <>
            <Toast ref={toastRef} />
            <Dialog
                style={{ width: '80vw', ...style }}
                {...rest}
                onHide={handleOnHide}
            >
                {modelFormat &&
                    Object.keys(rowFields).map((i) => {
                        return (
                            <div
                                key={rowFields[i].label}
                                style={{
                                    marginBottom: '16px',
                                    display: 'grid',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                            >
                                <span>{rowFields[i].label}</span>
                                {getFieldInput(
                                    rowFields[i].label,
                                    rowFields[i].type,
                                    rowFields[i].value
                                )}
                            </div>
                        );
                    })}
                <Button
                    label='Create'
                    onClick={handleCreate}
                    loading={createMutate.isLoading}
                />
            </Dialog>
        </>
    );
};

export default CreateDataDialog;
