import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { createContext, useContext, useEffect } from 'react';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import { getConfigData } from 'services/ether/backoffice/manager';
import { Ether } from 'types';
import { useConfigModelQuery } from '../..';
import GenericTemplate from './components/GenericTemplate';
import ListTemplate from './components/ListTemplate';

export interface ITemplateDisplay {
    model: Ether.BackOffice.IConfigModel;
    data: any;
}

const ConfigDataContext = createContext<UseQueryResult<any, Error> | null>(
    null
);

const DisplayConfigData = () => {
    const configModelQuery = useConfigModelQuery();
    const errorBoundary = useErrorBoundary();

    const configDataQuery = useQuery<any, Error>(
        ['get-config-data', configModelQuery?.data?.config_key],
        () => {
            if (!configModelQuery?.data?.config_key)
                throw new Error('missing config key');
            return getConfigData(configModelQuery.data.config_key);
        },
        {
            enabled: !!configModelQuery?.data?.config_key,
        }
    );

    useEffect(() => {
        if (configModelQuery?.error)
            errorBoundary.showBoundary(configModelQuery.error);
    }, [configModelQuery?.error, errorBoundary]);

    return (
        <div>
            <h1>{configModelQuery?.data?.name} data</h1>
            {configModelQuery?.isLoading && <p>Loading model...</p>}
            {configDataQuery?.isLoading && <p>Loading data...</p>}
            <ErrorBoundary
                FallbackComponent={({ error, resetErrorBoundary }) => {
                    return (
                        <div>
                            <h2>Failed to load data</h2>
                            <p>
                                The model and data might have a view mismatch.
                            </p>
                            <p>{error.message}</p>
                            <Button
                                label='Retry'
                                onClick={resetErrorBoundary}
                                className='p-button-outlined'
                            />
                        </div>
                    );
                }}
            >
                <ConfigDataContext.Provider value={configDataQuery}>
                    {configModelQuery?.data &&
                        configDataQuery.isSuccess &&
                        (configModelQuery.data.model_template === 'generic' ? (
                            <GenericTemplate
                                model={configModelQuery.data}
                                data={configDataQuery.data}
                            />
                        ) : (
                            <ListTemplate
                                model={configModelQuery.data}
                                data={configDataQuery.data}
                            />
                        ))}
                </ConfigDataContext.Provider>
            </ErrorBoundary>
        </div>
    );
};

export const useConfigDataQuery = () => {
    const context = useContext(ConfigDataContext);
    return context;
};

export default DisplayConfigData;
