import { Datepicker } from 'components/ethercity-primereact';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';

import './style.css';

const DateFilter = (options: ColumnFilterElementTemplateOptions) => {
    return (
        <Datepicker
            style={{
                width: '100%',
            }}
            value={options.value}
            onChange={(value) => options.filterCallback(value)}
        />
    );
};

export default DateFilter;
