import { useMutation, useQuery } from '@tanstack/react-query';
import { Dropdown, InputText } from 'components/ethercity-primereact';
import { useToast } from 'hooks/useToast';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    CreateConfigData,
    createConfigModel,
} from 'services/ether/backoffice/config-model';
import { listGroups } from 'services/ether/backoffice/groups';
import { Ether } from 'types';

const validTypeChoices = [
    { label: 'Text', value: 'str' },
    { label: 'Integer Number', value: 'int' },
    { label: 'Decimal Number', value: 'float' },
    { label: 'Key/value', value: 'dict' },
    { label: 'List', value: 'list' },
    { label: 'Boolean', value: 'bool' },
    { label: 'Date', value: 'datetime' },
];

const GroupSelector: React.FC<{
    value: string | null;
    onChange: (value: string) => void;
}> = (props) => {
    const groupQuery = useQuery(['list-groups'], () => listGroups());

    return (
        <Dropdown
            label='Group'
            required
            loading={groupQuery.isLoading}
            options={groupQuery.data?.map((g) => ({
                label: g.name,
                value: g._id.toHexString(),
            }))}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
        />
    );
};

export const ListFieldsSelection: React.FC<{
    fields: { name: string; type: string }[];
    onAddField: () => void;
    onRemoveField: (fieldIndex: number) => void;
    handleFieldNameChange: (fieldIndex: number, value: string) => void;
    handleFieldTypeChange: (
        fieldIndex: number,
        value: Ether.BackOffice.FieldTypes
    ) => void;
}> = ({
    onAddField,
    onRemoveField,
    fields,
    handleFieldNameChange,
    handleFieldTypeChange,
}) => {
    return (
        <div style={{width: '100%'}}>
            <h3>Fields</h3>
            <div
                style={{
                    display: 'grid',
                    gap: '16px',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    alignItems: 'end',
                }}
            >
                {fields.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '2fr 2fr min-content',
                            alignItems: 'end',
                            gap: '8px',
                        }}
                    >
                        <InputText
                            label='Field name'
                            value={item.name}
                            onChange={(e) =>
                                handleFieldNameChange(index, e.target.value)
                            }
                            required={
                                fields.every((a) => a.name === '')
                                    ? true
                                    : undefined
                            }
                            wrapperStyle={{
                                width: '100%'
                            }}
                        />
                        <Dropdown
                            label='Field type'
                            style={{ width: '100%' }}
                            value={item.type}
                            onChange={(e) =>
                                handleFieldTypeChange(index, e.target.value)
                            }
                            options={validTypeChoices}
                        />
                        {fields.length > 1 && (
                            <Button
                                icon='pi pi-minus'
                                onClick={() => onRemoveField(index)}
                                className='p-button-outlined'
                            />
                        )}
                    </div>
                ))}
                <Button
                    icon='pi pi-plus'
                    label='Add new field'
                    onClick={onAddField}
                    className='p-button-outlined'
                />
            </div>
        </div>
    );
};

const CreateConfigModel = () => {
    const toast = useToast();
    const navigate = useNavigate();

    const [formData, setFormData] = useState<{
        name: string;
        groupId: string | null;
        configKey: string;
        modelTemplate: Ether.BackOffice.IConfigModel['model_template'];
        setup: {
            listFieldName: string;
            listFields: { name: string; type: Ether.BackOffice.FieldTypes }[];
        };
    }>({
        name: '',
        groupId: null,
        configKey: '',
        modelTemplate: 'generic',
        setup: {
            listFieldName: '',
            listFields: [
                {
                    name: '',
                    type: 'str',
                },
            ],
        },
    });

    const [jsonPreview, setJsonPreview] = useState<{
        [key: string]: any;
    } | null>(null);

    useEffect(() => {
        if (formData.configKey === '') {
            setJsonPreview(null);
            return;
        }
        const getBasicExample = (type: Ether.BackOffice.FieldTypes) => {
            switch (type) {
                case 'int':
                    return 0;
                case 'float':
                    return 0.5;
                case 'bool':
                    return true;
                case 'list':
                    return ['item1', 'item2'];
                case 'dict':
                    return { key: 'value' };
                case 'datetime':
                    return new Date();
                default:
                    return type;
            }
        };

        const newObj: any = {};
        newObj[formData.configKey] = {
            data: {},
            timestamp: new Date(),
        };
        if (formData.modelTemplate === 'list') {
            const exampleObj: any = {};

            formData.setup.listFields
                .filter((i) => i.name !== '')
                .forEach((i) => {
                    exampleObj[i.name] = getBasicExample(i.type);
                });

            if (formData.setup.listFieldName === '') {
                newObj[formData.configKey].data = [exampleObj];
            } else {
                newObj[formData.configKey].data[formData.setup.listFieldName] =
                    [exampleObj];
            }
        }
        setJsonPreview(newObj);
    }, [formData]);

    const createMutate = useMutation(
        async () => {
            if (!formData.groupId) return;
            const data: CreateConfigData = {
                name: formData.name,
                group_id : formData.groupId,
                config_key: formData.configKey,
                model_template: formData.modelTemplate,
                setup: {},
            };

            if (formData.modelTemplate === 'list') {
                const fields: { [key: string]: Ether.BackOffice.FieldTypes } =
                    {};
                formData.setup.listFields
                    .filter((item) => item.name !== '')
                    .forEach((item) => {
                        fields[item.name] = item.type;
                    });

                data.setup = {
                    list_fields: fields,
                };

                if (formData.setup.listFieldName !== '')
                    data.setup.list_field_name = formData.setup.listFieldName;
            }

            return createConfigModel(data);
        },
        {
            onSuccess: () => {
                toast?.show({
                    severity: 'success',
                    summary: 'Succesfully created config model',
                });
                navigate('..');
            },
            onError: (err: Error) => {
                toast?.show({
                    severity: 'error',
                    summary: 'Error creating',
                    detail: err.message,
                });
            },
        }
    );

    return (
        <div>
            <h1>Create config</h1>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    alignItems: 'start',
                }}
            >
                <InputText
                    label='Name'
                    required
                    value={formData.name}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            name: e.target.value,
                        }))
                    }
                />
                <GroupSelector
                    value={formData.groupId}
                    onChange={(value) =>
                        setFormData((old) => ({
                            ...old,
                            groupId: value,
                        }))
                    }
                />
                <InputText
                    label='Config key'
                    required
                    value={formData.configKey}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            configKey: e.target.value,
                        }))
                    }
                />
                <Dropdown
                    label='Model type'
                    options={[
                        {
                            label: 'Generic',
                            value: 'generic',
                        },
                        {
                            label: 'List',
                            value: 'list',
                        },
                    ]}
                    value={formData.modelTemplate}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            modelTemplate: e.target.value,
                        }))
                    }
                />
                {formData.modelTemplate === 'list' && (
                    <>
                        <InputText
                            label='List field'
                            required={false}
                            tooltip="If not defined, the list will default to the config's data field."
                            value={formData.setup?.listFieldName}
                            onChange={(e) =>
                                setFormData((old) => {
                                    const newData = { ...old };
                                    newData.setup.listFieldName =
                                        e.target.value;
                                    return newData;
                                })
                            }
                        />
                        <ListFieldsSelection
                            fields={formData.setup.listFields}
                            onAddField={() =>
                                setFormData((old) => {
                                    const newData = { ...old.setup };
                                    const fields = [
                                        ...newData.listFields,
                                        {
                                            name: '',
                                            type: 'str' as Ether.BackOffice.FieldTypes,
                                        },
                                    ];
                                    newData.listFields = fields;
                                    return { ...old, setup: newData };
                                })
                            }
                            onRemoveField={(index) =>
                                setFormData((old) => {
                                    const newData = { ...old.setup };
                                    const fields = [...newData.listFields];
                                    fields.splice(index, 1);
                                    newData.listFields = fields;
                                    return { ...old, setup: newData };
                                })
                            }
                            handleFieldNameChange={(index, value) =>
                                setFormData((old) => {
                                    const newData = { ...old };
                                    newData.setup.listFields[index].name =
                                        value;
                                    return newData;
                                })
                            }
                            handleFieldTypeChange={(
                                index,
                                value: Ether.BackOffice.FieldTypes
                            ) =>
                                setFormData((old) => {
                                    const newData = { ...old };
                                    newData.setup.listFields[index].type =
                                        value;
                                    return newData;
                                })
                            }
                        />
                    </>
                )}
            </div>
            <h3>Config preview</h3>
            <pre
                style={{
                    fontSize: '12px',
                    lineHeight: '16px',
                }}
            >
                {jsonPreview != null
                    ? JSON.stringify(jsonPreview, null, 4)
                    : 'Nothing to preview'}
            </pre>
            <Button
                icon='pi pi-plus'
                label='Create'
                onClick={() => createMutate.mutate()}
                disabled={
                    formData.name === '' ||
                    formData.groupId == null ||
                    formData.configKey === '' ||
                    (formData.modelTemplate === 'list' &&
                        formData.setup.listFields.some((a) => a.name === ''))
                }
            />
        </div>
    );
};

export default CreateConfigModel;
