import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import Papa from 'papaparse';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableSortMeta } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';

import { useToast } from 'hooks/useToast';
import {
    getColumnPropsForType,
    getInputForType,
    getTypeInitialData,
    parseValueForType,
} from 'utils/fieldTypeUtils';
import {
    listTemplateDeleteConfigData,
    listTemplateInsertConfigData,
    listTemplateUpdateConfigData,
    listTemplateUpdateIndexConfigData,
} from 'services/ether/backoffice/manager';

import { Ether } from 'types';

import { ITemplateDisplay, useConfigDataQuery } from '../..';
import { ObjectDisplayModal } from 'components/ethercity-primereact';
import downloadFromUrl from 'utils/downloadFromUrl';

const getNameAndFieldTypeList = (
    data: Ether.BackOffice.IConfigModel['setup']['list_fields']
) => {
    if (!data) return [];
    return Object.keys(data).map((name) => ({
        name,
        fieldType: data[name],
    }));
};

const DataManageModal = (props: {
    data: { [key: string]: any };
    model: Ether.BackOffice.IConfigModel;
    isValid: boolean;
    onDataChange: (field: string, value: any) => void;
    onValidation: (field: string, value: boolean) => void;
    modalProps: {
        visible: boolean;
        onHide: () => void;
        header?: string;
    };
    onSubmit: (data: any) => void;
    submitButtonLabel?: string;
}) => {
    if (!props.model.setup.list_fields) return <></>;
    return (
        <Dialog {...props.modalProps}>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: '8px',
                    marginBottom: '8px',
                }}
            >
                {getNameAndFieldTypeList(props.model.setup.list_fields).map(
                    (item) => {
                        return getInputForType(
                            item.fieldType,
                            {
                                name: item.name,
                                value: props.data[item.name],
                                onChange: (value) =>
                                    props.onDataChange(item.name, value),
                                handleIsValid: (value) => {
                                    props.onValidation(item.name, value);
                                },
                            },
                            {
                                label: item.name,
                            }
                        );
                    }
                )}
            </div>
            <Button
                label={props.submitButtonLabel ?? 'Submit'}
                disabled={!props.isValid}
                onClick={() => props.onSubmit(props.data)}
            />
        </Dialog>
    );
};

const ListTemplate: React.FC<ITemplateDisplay> = ({ model, data }) => {
    const toast = useToast();
    const configDataQuery = useConfigDataQuery();
    const fileUploadRef = useRef<FileUpload>(null);

    const [uploadData, setUploadData] = useState<
        { [key: string]: any }[] | null
    >(null);
    const [uploadDataStatus, setUploadDataStatus] = useState<
        'idle' | 'loading' | 'pending' | 'uploading'
    >('idle');

    const setInitialData = () => {
        const initData: { [key: string]: any } = {};
        getNameAndFieldTypeList(model.setup.list_fields).forEach((item) => {
            initData[item.name] = getTypeInitialData(item.fieldType);
        });
        return initData;
    };

    const [selectedInfo, setSelectedInfo] = useState<any | null>(null);

    const [createItemModalVisible, setCreateItemModalVisible] = useState(false);
    const [createItemData, setCreateItemData] = useState<{
        [key: string]: any;
    }>(setInitialData);
    const [createItemIsValid, setCreateItemIsValid] = useState<{
        [key: string]: boolean;
    }>(() => {
        const initData: { [key: string]: boolean } = {};
        getNameAndFieldTypeList(model.setup.list_fields).forEach((item) => {
            initData[item.name] = true;
        });
        return initData;
    });

    const [updateItemModalVisible, setUpdateItemModalVisible] = useState(false);
    const [updateItemIndex, setUpdateItemIndex] = useState<number | null>(null);
    const [updateItemData, setUpdateItemData] = useState<{
        [key: string]: any;
    }>(setInitialData);
    const [updateItemIsValid, setUpdateItemIsValid] = useState<{
        [key: string]: boolean;
    }>(() => {
        const initData: { [key: string]: boolean } = {};
        getNameAndFieldTypeList(model.setup.list_fields).forEach((item) => {
            initData[item.name] = true;
        });
        return initData;
    });

    const [deleteItemModalVisible, setDeleteItemModalVisible] = useState(false);
    const [deleteItemIndex, setDeleteItemIndex] = useState<number | null>(null);

    const parseData = (data: { [key: string]: any }) => {
        const newData: { [key: string]: any } = {};
        getNameAndFieldTypeList(model.setup.list_fields).forEach(
            ({ name, fieldType }) => {
                newData[name] = parseValueForType(data[name], fieldType);
            }
        );
        return newData;
    };

    const createItemMutation = useMutation<any, Error, any>(
        (data) =>
            listTemplateInsertConfigData(model.config_key, parseData(data)),
        {
            onSuccess: () => {
                toast?.show({
                    severity: 'success',
                    summary: 'Succesfully created entry',
                });
                configDataQuery?.refetch();
                setCreateItemData(setInitialData);
                handleCreateItemModalHide();
            },
            onError: (err) =>
                toast?.show({
                    severity: 'error',
                    summary: 'Failed to create entry',
                    detail: err.message,
                }),
        }
    );

    const uploadListMutation = useMutation<
        any,
        Error,
        { [key: string]: any }[]
    >((data) => listTemplateUpdateConfigData(model.config_key, data), {
        onSuccess: () => {
            toast?.show({
                severity: 'success',
                summary: 'Succesfully updated config',
            });
            configDataQuery?.refetch();
        },
        onError: (err) =>
            toast?.show({
                severity: 'error',
                summary: 'Failed to update config',
                detail: err.message,
            }),
        onSettled: () => handleClearUpload(),
    });

    const updateItemMutation = useMutation<
        any,
        Error,
        { data: any; index: number }
    >(
        (data) =>
            listTemplateUpdateIndexConfigData(
                model.config_key,
                parseData(data.data),
                data.index
            ),
        {
            onSuccess: () => {
                toast?.show({
                    severity: 'success',
                    summary: 'Succesfully updated entry',
                });
                configDataQuery?.refetch();
                setUpdateItemData(setInitialData);
                handleUpdateItemModalHide();
            },
            onError: (err) =>
                toast?.show({
                    severity: 'error',
                    summary: 'Failed to update entry',
                    detail: err.message,
                }),
        }
    );

    const deleteItemMutation = useMutation<any, Error, number>(
        (index: number) =>
            listTemplateDeleteConfigData(model.config_key, index),
        {
            onSuccess: () => {
                toast?.show({
                    severity: 'success',
                    summary: 'Succesfully deleted entry',
                });
                configDataQuery?.refetch();
            },
            onError: (err) =>
                toast?.show({
                    severity: 'error',
                    summary: 'Failed to delete entry',
                    detail: err.message,
                }),
            onSettled: () => handleDeleteItemModalHide(),
        }
    );

    const handleCreateItemModalHide = () => {
        setCreateItemModalVisible(false);
    };

    const handleUpdateItemModalHide = () => {
        setUpdateItemIndex(null);
        setUpdateItemModalVisible(false);
    };

    const handleDeleteItemModalHide = () => {
        setDeleteItemIndex(null);
        setDeleteItemModalVisible(false);
    };

    const getListField = useCallback<() => { [key: string]: any }[]>(() => {
        if (data == null) return [];
        if (model.setup.list_field_name)
            return data[model.setup.list_field_name] ?? [];
        return data;
    }, [model.setup.list_field_name, data]);

    useEffect(() => {
        if (updateItemIndex == null) return;
        setUpdateItemData(parseDataForForm(getListField()[updateItemIndex]));
        setUpdateItemModalVisible(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateItemIndex, getListField]);

    const renderColumns = (isPreview = false) => {
        if (!model.setup.list_fields)
            throw new Error(
                'no setup.list_fields defined for column rendering'
            );
        return [
            ...getNameAndFieldTypeList(model.setup.list_fields).map((item) => {
                return (
                    <Column
                        key={item.name}
                        field={item.name}
                        header={item.name}
                        body={(data) =>
                            !(data[item.name] == null)
                                ? data[item.name].toString()
                                : '-'
                        }
                        {...getColumnPropsForType(item.fieldType, item.name)}
                        {...(isPreview ? { filter: false } : {})}
                    />
                );
            }),
            ...(!isPreview
                ? [
                      <Column
                          key='actionfield'
                          body={(rowData) => (
                              <div style={{ display: 'flex', gap: '8px' }}>
                                  <Button
                                      className='p-button-outlined'
                                      tooltip='View'
                                      icon='pi pi-search'
                                      onClick={() =>
                                          setSelectedInfo(
                                              getListField()[rowData.___index]
                                          )
                                      }
                                  />
                                  <Button
                                      className='p-button-outlined'
                                      tooltip='Edit'
                                      icon='pi pi-pencil'
                                      onClick={() =>
                                          setUpdateItemIndex(rowData.___index)
                                      }
                                  />
                                  <Button
                                      severity='danger'
                                      tooltip='Delete'
                                      icon='pi pi-trash'
                                      onClick={() => {
                                          setDeleteItemModalVisible(true);
                                          setDeleteItemIndex(rowData.___index);
                                      }}
                                  />
                              </div>
                          )}
                      />,
                  ]
                : []),
        ];
    };

    const DeleteModal: React.FC<{
        visible: boolean;
        targetItem: number | null;
        onHide: () => void;
        onDelete: (index: number) => void;
    }> = ({ visible, onHide, onDelete, targetItem }) => {
        return (
            <Dialog visible={visible} onHide={onHide} header='Delete item'>
                <p>Are you sure you want to delete this item?</p>
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Button
                        label='Yes'
                        severity='danger'
                        onClick={() =>
                            !(targetItem == null) && onDelete(targetItem)
                        }
                    />
                    <Button label='No' onClick={onHide} />
                </div>
            </Dialog>
        );
    };

    const parseDataForForm = (data: any) => {
        const obj: any = {};
        Object.keys(data).forEach((k) => {
            if (!model.setup.list_fields) return;
            const type = model.setup.list_fields[k];
            if (type === 'datetime')
                obj[k] = data[k] != null ? new Date(data[k]) : null;
            else if (type === 'list' || type === 'dict')
                obj[k] = data[k] != null ? JSON.stringify(data[k]) : '';
            else obj[k] = data[k];
        });
        return obj;
    };

    const parseDataTableValue = (data: { [key: string]: any }[]) => {
        return data
            .map((item: any, index: number) => ({
                ...item,
                ___index: index,
            }))
            .map((item: any) => {
                const obj: any = {};
                Object.keys(item).forEach((k) => {
                    if (!model.setup.list_fields) return;
                    const type = model.setup.list_fields[k];
                    if (type === 'datetime')
                        obj[k] = item[k] != null ? new Date(item[k]) : null;
                    else if (type === 'list' || type === 'dict')
                        obj[k] =
                            item[k] != null ? JSON.stringify(item[k]) : null;
                    else obj[k] = item[k];
                });
                return obj;
            });
    };

    const generateCsv = (data: { [key: string]: any }[]) => {
        const headers: string[] = [
            ...new Set(data.map((item) => Object.keys(item)).flat()),
        ];
        const csv = Papa.unparse({
            fields: headers,
            data: data,
        });
        const blob = new Blob([csv]);
        const fileDownloadUrl = URL.createObjectURL(blob);
        downloadFromUrl(
            fileDownloadUrl,
            `${
                model.name.replace(' ', '-') ?? model.config_key
            }_${new Date().toISOString()}`,
            '.csv'
        );
        URL.revokeObjectURL(fileDownloadUrl);
    };

    const loadCsv = (
        file: File,
        onComplete: (data: { [key: string]: any }[]) => void,
        onError: (error: string) => void
    ) => {
        const handleError = (error: Error) => {
            console.error(error);
            onError(error.message);
        };
        if (!model.setup.list_fields) {
            handleError(new Error('no setup.list_fields'));
            return;
        }
        const listFieldSetup = model.setup.list_fields;
        Papa.parse(file, {
            complete: (results: Papa.ParseResult<string[]>) => {
                const { data } = results;
                const headers = data[0];
                const modelHeaders = Object.keys(listFieldSetup);
                const validHeaders =
                    modelHeaders.every(
                        (key) => !!headers.find((key2) => key2 === key)
                    ) && modelHeaders.length === headers.length;
                if (!validHeaders) {
                    handleError(
                        new Error(
                            `headers does not match, expected ${modelHeaders} received ${headers}`
                        )
                    );
                    return;
                }

                const newData: { [key: string]: any }[] = [];
                data.forEach((values, index) => {
                    if (index === 0) return;
                    const item: { [key: string]: any } = {};
                    values.forEach((value, index) => {
                        item[headers[index]] = parseValueForType(
                            value,
                            listFieldSetup[headers[index]]
                        );
                    });
                    newData.push(item);
                });
                onComplete(newData);
            },
        });
    };

    const handleClearUpload = () => {
        setUploadData(null);
        setUploadDataStatus('idle');
        fileUploadRef.current?.clear();
    };

    const [sort, setSort] = useState<DataTableSortMeta>({
        field: '',
        order: null,
    });

    return (
        <div>
            <ObjectDisplayModal
                displayData={selectedInfo}
                visible={!!selectedInfo}
                onHide={() => setSelectedInfo(null)}
            />
            <DeleteModal
                visible={deleteItemModalVisible}
                onHide={handleDeleteItemModalHide}
                targetItem={deleteItemIndex}
                onDelete={(index) => deleteItemMutation.mutate(index)}
            />
            <DataManageModal
                data={createItemData}
                model={model}
                isValid={Object.keys(createItemIsValid).every(
                    (key) => createItemIsValid[key]
                )}
                onDataChange={(name, value) =>
                    setCreateItemData((old) => {
                        const newData = { ...old };
                        newData[name] = value;
                        return newData;
                    })
                }
                onValidation={(name, value) =>
                    setCreateItemIsValid((old) => {
                        const newData = { ...old };
                        newData[name] = value;
                        return newData;
                    })
                }
                modalProps={{
                    header: 'Create item',
                    visible: createItemModalVisible,
                    onHide: handleCreateItemModalHide,
                }}
                onSubmit={(data) => createItemMutation.mutate(data)}
            />
            <DataManageModal
                data={updateItemData}
                model={model}
                isValid={Object.keys(updateItemIsValid).every(
                    (key) => updateItemIsValid[key]
                )}
                onDataChange={(name, value) =>
                    setUpdateItemData((old) => {
                        const newData = { ...old };
                        newData[name] = value;
                        return newData;
                    })
                }
                onValidation={(name, value) => {
                    setUpdateItemIsValid((old) => {
                        const newData = { ...old };
                        newData[name] = value;
                        return newData;
                    });
                }}
                modalProps={{
                    header: 'Update item',
                    visible: updateItemModalVisible,
                    onHide: handleUpdateItemModalHide,
                }}
                onSubmit={(data) => {
                    if (updateItemIndex == null) return;
                    updateItemMutation.mutate({
                        index: updateItemIndex,
                        data: data,
                    });
                }}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '8px',
                    marginBottom: '8px',
                }}
            >
                <Button
                    label='Create'
                    className='p-button-outlined'
                    icon='pi pi-plus'
                    onClick={() => setCreateItemModalVisible(true)}
                />
                <Button
                    label='Export CSV'
                    className='p-button-outlined'
                    icon='pi pi-download'
                    onClick={() =>
                        generateCsv(
                            getListField().map((a) => parseDataForForm(a))
                        )
                    }
                />
                <FileUpload
                    mode='basic'
                    accept='.csv'
                    chooseOptions={{
                        className: 'p-button-outlined',
                        label: 'Import CSV',
                        icon: 'pi pi-upload',
                    }}
                    ref={fileUploadRef}
                    customUpload
                    onSelect={(e) => {
                        setUploadDataStatus('loading');
                        loadCsv(
                            e.files[0],
                            (data) => {
                                setUploadData(data);
                                setUploadDataStatus('pending');
                            },
                            (err) => {
                                toast?.show({
                                    severity: 'error',
                                    summary: 'File error',
                                    detail: err,
                                    life: 20000,
                                });
                                handleClearUpload();
                            }
                        );
                    }}
                />
            </div>
            <Dialog
                visible={uploadDataStatus === 'pending'}
                onHide={handleClearUpload}
                header='Preview data'
            >
                {uploadData && (
                    <>
                        <DataTable value={parseDataTableValue(uploadData)}>
                            {renderColumns(true)}
                        </DataTable>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '8px',
                                marginTop: '8px',
                            }}
                        >
                            <Button
                                className='p-button-outlined'
                                label='Upload'
                                icon='pi pi-upload'
                                onClick={() =>
                                    uploadListMutation.mutate(uploadData)
                                }
                            />
                            <Button
                                severity='danger'
                                label='Cancel'
                                icon='pi pi-times'
                                onClick={handleClearUpload}
                            />
                        </div>
                    </>
                )}
            </Dialog>
            <DataTable
                sortField={sort.field}
                sortOrder={sort.order}
                onSort={(e) =>
                    setSort({
                        field: e.sortField,
                        order: e.sortOrder,
                    })
                }
                value={parseDataTableValue(getListField())}
                removableSort
            >
                {renderColumns()}
            </DataTable>
        </div>
    );
};

export default ListTemplate;
