import { Ether } from 'types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import {
    DataTable,
    DataTableFilterMeta,
    DataTableSortMeta,
} from 'primereact/datatable';
import {
    deleteConfigModel,
    listConfigModels,
} from 'services/ether/backoffice/config-model';
import { Link } from 'react-router-dom';
import { Column } from 'primereact/column';
import {
    Dropdown,
    ObjectDisplayModal,
    OidBadge,
} from 'components/ethercity-primereact';
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { useToast } from 'hooks/useToast';
import { useAuth } from 'hooks/useAuth';
import { listGroups } from 'services/ether/backoffice/groups';
import OidFilter from 'components/filters/OidFilter';

const DeleteModal: React.FC<{
    visible: boolean;
    targetConfig: Ether.BackOffice.IConfigModel | null;
    onHide: () => void;
    onDelete: (id: Ether.BackOffice.IConfigModel) => void;
}> = ({ visible, onHide, onDelete, targetConfig }) => {
    return (
        <Dialog
            visible={visible}
            onHide={onHide}
            header={`Delete ${targetConfig?.name}`}
        >
            <p>
                Are you sure you want to delete the model {targetConfig?.name}?
                The config data will not be deleted.
            </p>
            <div style={{ display: 'flex', gap: '8px' }}>
                <Button
                    label='Yes'
                    severity='danger'
                    onClick={() => targetConfig && onDelete(targetConfig)}
                />
                <Button label='No' onClick={onHide} />
            </div>
        </Dialog>
    );
};

const ConfigModel = () => {
    const { permissions } = useAuth();
    const [groupsMap, setGroupsMap] = useState<{ [key: string]: string }>({});
    const groupQuery = useQuery(['list-groups'], () => listGroups(), {
        enabled: permissions?.name === 'admin',
        onSuccess: (groups) => {
            setGroupsMap(() => {
                const map: { [key: string]: string } = {};
                groups.forEach(
                    (group) => (map[group._id.toHexString()] = group.name)
                );
                return map;
            });
            groups.forEach((group) => {});
        },
    });

    const toast = useToast();

    const [filters, setFilters] = useState<DataTableFilterMeta>({
        _id: {
            matchMode: 'equals',
            value: null,
        },
        group_id: {
            matchMode: 'equals',
            value: null,
        },
        name: {
            constraints: [
                {
                    matchMode: 'contains',
                    value: null,
                },
            ],
            operator: 'and',
        },
    });
    const [sort, setSort] = useState<DataTableSortMeta | undefined>(undefined);

    const configModelQuery = useQuery<Ether.BackOffice.IConfigModel[], Error>(
        ['list-config-model', filters, sort],
        () =>
            listConfigModels({
                limit: 10000,
                filters,
                sort,
            })
    );

    const [selectedConfigModel, setSelectedConfigModel] =
        useState<Ether.BackOffice.IConfigModel | null>(null);

    const deleteMutation = useMutation<
        boolean,
        Error,
        Ether.BackOffice.IConfigModel
    >((config) => deleteConfigModel(config._id.toString()), {
        onSuccess: (_, config) => {
            toast?.show({
                severity: 'success',
                summary: 'Successfully deleted ' + config.name,
            });
            configModelQuery.refetch();
        },
        onError: (err, config) => {
            toast?.show({
                severity: 'error',
                summary: 'Error deleting ' + config.name,
                detail: err.message,
            });
        },
        onSettled: () => hideDeleteModal(),
    });

    const [selectedConfigForDeletion, setSelectedConfigForDeletion] =
        useState<Ether.BackOffice.IConfigModel | null>(null);

    const hideDeleteModal = () => {
        setSelectedConfigForDeletion(null);
    };

    return (
        <div>
            <ObjectDisplayModal
                displayData={selectedConfigModel}
                visible={!!selectedConfigModel}
                onHide={() => setSelectedConfigModel(null)}
            />
            <DeleteModal
                visible={!!selectedConfigForDeletion}
                onHide={hideDeleteModal}
                onDelete={deleteMutation.mutate}
                targetConfig={selectedConfigForDeletion}
            />
            <h1>Config List</h1>
            <div style={{ marginBottom: '8px' }}>
                {permissions?.name === 'admin' && (
                    <Link to='create'>
                        <Button
                            label='Create Config'
                            className='p-button-outlined'
                            icon='pi pi-plus'
                        />
                    </Link>
                )}
            </div>
            {configModelQuery.error ? (
                <h3>Error listing: {configModelQuery.error.message}</h3>
            ) : (
                <DataTable
                    lazy
                    value={configModelQuery.data}
                    emptyMessage='No config models'
                    filters={filters}
                    onFilter={(e) => setFilters(e.filters)}
                    sortField={sort?.field}
                    sortOrder={sort?.order}
                    onSort={(e) =>
                        setSort({
                            field: e.sortField,
                            order: e.sortOrder,
                        })
                    }
                    removableSort
                >
                    <Column
                        header='OID'
                        field='_id'
                        body={(data: Ether.BackOffice.IConfigModel) => {
                            return <OidBadge value={data._id.toString()} />;
                        }}
                        sortable
                        filter
                        showFilterMatchModes={false}
                        filterElement={OidFilter}
                    />
                    {permissions?.name === 'admin' && groupQuery.isSuccess && (
                        <Column
                            header='Group'
                            field='group_id'
                            body={(row: Ether.BackOffice.IConfigModel) => {
                                const id = row.group_id;
                                if (!id) return <Badge value='-' />;
                                return groupsMap[id.toHexString()];
                            }}
                            filter
                            showFilterMatchModes={false}
                            filterElement={(options) => {
                                return (
                                    <Dropdown
                                        style={{ width: '100%' }}
                                        options={Object.keys(groupsMap).map(
                                            (k) => ({
                                                value: k,
                                                label: groupsMap[k],
                                            })
                                        )}
                                        value={options.value}
                                        onChange={(e) => options.filterCallback(e.target.value)}
                                    />
                                );
                            }}
                        />
                    )}
                    <Column
                        header='Name'
                        field='name'
                        filter
                        showAddButton={false}
                        showFilterOperator={false}
                    />
                    <Column header='Key' field='config_key' />
                    <Column
                        header='Template'
                        field='model_template'
                        body={(data: Ether.BackOffice.IConfigModel) => {
                            return (
                                <Badge
                                    severity='info'
                                    value={data.model_template.toUpperCase()}
                                />
                            );
                        }}
                    />
                    <Column
                        header='Actions'
                        body={(data: Ether.BackOffice.IConfigModel) => {
                            return (
                                <div style={{ display: 'flex', gap: '8px' }}>
                                    <Link to={`${data._id}/display`}>
                                        <Button
                                            className='p-button-outlined'
                                            label='View data'
                                        />
                                    </Link>
                                    <Button
                                        className='p-button-outlined'
                                        tooltip='View'
                                        icon='pi pi-search'
                                        onClick={() =>
                                            setSelectedConfigModel(data)
                                        }
                                    />
                                    {permissions?.name === 'admin' && (
                                        <>
                                            <Link to={`${data._id}/update`}>
                                                <Button
                                                    className='p-button-outlined'
                                                    tooltip='Edit'
                                                    icon='pi pi-pencil'
                                                />
                                            </Link>
                                            <Button
                                                severity='danger'
                                                tooltip='Delete'
                                                icon='pi pi-trash'
                                                onClick={() =>
                                                    setSelectedConfigForDeletion(
                                                        data
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            );
                        }}
                    />
                </DataTable>
            )}
        </div>
    );
};

export default ConfigModel;
