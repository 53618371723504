import {
    Outlet,
    Navigate,
    Routes as ReactRoutes,
    Route,
} from 'react-router-dom';

import Main from 'pages/Main';

import Home from 'pages/Main/subpages/Home';
import Login from 'pages/Login';

import ConfigModel from 'pages/Main/subpages/ConfigModel';
import ListConfigModel from 'pages/Main/subpages/ConfigModel/subpages/ListConfigModel';
import CreateConfigModel from 'pages/Main/subpages/ConfigModel/subpages/CreateConfigModel';
import UpdateConfigModel from 'pages/Main/subpages/ConfigModel/subpages/UpdateConfigModel';
import DisplayConfigData from 'pages/Main/subpages/ConfigModel/subpages/DisplayConfigData';

import ModelViewer from 'pages/Main/subpages/ModelViewer';

import NotFound from 'pages/NotFound';

import { useAuth } from 'hooks/useAuth';
import { BreadCrumbProvider } from 'hooks/useBreadCrumb';

export interface IRoute {
    path: string;
    key: string;
    element?: JSX.Element;
    children?: IRoute[];

    breadcrumbOptions?: {
        label?: string;
        validUrl?: boolean;
        ignoreRender?: boolean;
    };
}

const ProtectedRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { status } = useAuth();
    if (status === 'loading')
        return (
            <div style={{ padding: '0px 16px' }}>
                <h3>Validating login...</h3>
            </div>
        );
    if (status === 'unauthenticated') return <Navigate to='/login' />;
    return children as React.ReactElement;
};

const AdminRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { permissions } = useAuth();
    if (!permissions)
        return (
            <div style={{ padding: '0px 16px' }}>
                <h3>Validating permissions...</h3>
            </div>
        );
    if (permissions.name !== 'admin') return <Navigate to='/' />;
    return children as React.ReactElement;
};

export const routes: IRoute[] = [
    {
        path: '/login',
        element: <Login />,
        key: 'login',
    },
    {
        path: '/',
        element: (
            <ProtectedRoute>
                <Main />
            </ProtectedRoute>
        ),
        key: 'mainroute',
        breadcrumbOptions: {
            ignoreRender: true,
        },
        children: [
            { path: '@index', key: 'home', element: <Home /> },
            {
                path: 'config',
                key: 'config',
                element: (
                    <BreadCrumbProvider>
                        <ConfigModel>
                            <Outlet />
                        </ConfigModel>
                    </BreadCrumbProvider>
                ),
                breadcrumbOptions: {
                    label: 'Config',
                    validUrl: true,
                },
                children: [
                    {
                        path: '@index',
                        key: 'listconfigmodel',
                        element: <ListConfigModel />,
                    },
                    {
                        path: 'create',
                        key: 'createconfigmodel',
                        element: (
                            <AdminRoute>
                                <CreateConfigModel />
                            </AdminRoute>
                        ),
                        breadcrumbOptions: {
                            label: 'Create',
                            validUrl: true,
                        },
                    },
                    {
                        path: ':configModelOid',
                        key: 'configmodelbase',
                        children: [
                            {
                                path: 'update',
                                key: 'updateconfigmodel',
                                element: (
                                    <AdminRoute>
                                        <UpdateConfigModel />
                                    </AdminRoute>
                                ),
                                breadcrumbOptions: {
                                    label: 'Update',
                                },
                            },
                            {
                                path: 'display',
                                key: 'displayconfigmodel',
                                element: <DisplayConfigData />,
                                breadcrumbOptions: {
                                    label: 'View data',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'model-viewer',
                key: 'model-viewer',
                element: <ModelViewer />,
            },
        ],
    },
];

const getRouteProps = (route: IRoute) => {
    if (route.path === '@index') {
        return {
            index: true,
            key: route.key,
            element: route.element,
        };
    }
    return {
        path: route.path,
        key: route.key,
        element: route.element,
    };
};

const buildRoutes = (route: IRoute) => {
    const props: any = getRouteProps(route);

    if (route.children) {
        return (
            <Route {...props}>
                {route.children.map((i) => buildRoutes(i))}
                <Route path='*' element={<NotFound />} />
            </Route>
        );
    }
    return <Route {...props} />;
};

const Routes: React.FC = () => {
    return <ReactRoutes>{routes.map((r) => buildRoutes(r))}</ReactRoutes>;
};

export default Routes;
