import { useMutation, useQuery } from '@tanstack/react-query';
import { Dropdown, ObjectDisplayModal } from 'components/ethercity-primereact';
import { useToast } from 'hooks/useToast';
import {
    DataTableSortMeta,
    DataTableFilterMetaData,
} from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { Ether } from 'types';
import CreateDataDialog from './components/CreateDataDialog';
import ExportGroup from './components/ExportGroup';
import UpdateDataDialog from './components/UpdateDataDialog';

import { Button } from 'primereact/button';
import ListDataTableView from './components/ListDataTableView';
import { applyApiFilters } from './utils/exportUtils';
import {
    apiModelProxyDelete,
    apiModelProxyList,
    getModelsFromApi,
    listApiModels,
} from 'services/ether/backoffice/api-model';

export interface IModel extends Ether.IModels {
    name: string;
    id_label: string;
}

const ModelViewer = () => {
    const toast = useToast();

    // ***************
    // useState
    // ***************

    // Filter available URIs
    const apiModelQuery = useQuery(['list-api-model'], () => listApiModels());

    // Dialog
    const [createDataDialogVisible, setCreateDataDialogVisible] =
        useState(false);
    const [updateDataDialogVisible, setUpdateDataDialogVisible] =
        useState(false);

    const [dialogRowDataVisible, setDialogRowDataVisible] = useState(false);
    const [dialogRowData, setDialogRowData] = useState<{
        [key: string]: unknown;
    }>();

    // Selected and provided on Dropdown
    const [selectedApiSlug, setSelectedApiSlug] = useState<string>();
    const [selectedModel, setSelectedModel] = useState<IModel | null>();

    // DataTable control
    const [page, setPage] = useState<number>(1);
    const [amountPerPage, setAmountPerPage] = useState<number>(10);
    const [sortBy, setSortBy] = useState<{
        field: string;
        order: DataTableSortMeta['order'];
    }>();
    const [processedFilters, setProcessedFilters] = useState<{
        [key: string]: {
            value: unknown;
            filterType: DataTableFilterMetaData['matchMode'];
        };
    }>({});

    // DataTable
    const [models, setModels] = useState<{ label: string; value: IModel }[]>();

    // ***************
    // useFetch
    // ***************

    // Fetch _models
    const modelQuery = useQuery<Ether.IModelsData, Error>(
        ['get-model', selectedApiSlug],
        async () => {
            if (!selectedApiSlug) throw new Error('unreachable');
            const result = await getModelsFromApi(selectedApiSlug);
            return result;
        },
        {
            enabled: !!selectedApiSlug,
        }
    );

    // Fetch list-{model}
    const listDataQuery = useQuery<
        Ether.IApiResponse<
            {
                [key: string]: unknown;
            }[]
        >,
        Error
    >(
        [
            'list-data',
            selectedApiSlug,
            selectedModel?.name,
            {
                limit: amountPerPage,
                offset: (page - 1) * amountPerPage,
                sort: sortBy,
                filters: processedFilters,
            },
        ],
        async () => {
            if (!selectedApiSlug || !selectedModel?.name)
                throw new Error('unreachable');
            let extraParams: { [key: string]: string } = {};
            if (sortBy && sortBy.order !== 0 && sortBy.field)
                extraParams['order'] = `${sortBy.order === -1 ? '-' : ''}${
                    sortBy.field
                }`;
            if (processedFilters)
                extraParams = {
                    ...extraParams,
                    ...applyApiFilters(processedFilters),
                };

            extraParams['limit'] = amountPerPage.toString();
            extraParams['offset'] = ((page - 1) * amountPerPage).toString();

            const result = await apiModelProxyList(
                selectedApiSlug,
                selectedModel?.name,
                {
                    params: extraParams,
                }
            );
            return result;
        },
        {
            enabled: !!(selectedModel && modelQuery.status === 'success'),
        }
    );

    // ***************
    // Mutation
    // ***************

    const deleteMutate = useMutation<unknown, Error, string>(
        (oidToDelete) => {
            if (!selectedApiSlug || !selectedModel?.name)
                throw new Error('unreachable');
            return apiModelProxyDelete(
                selectedApiSlug,
                selectedModel?.name,
                oidToDelete
            );
        },
        {
            onSuccess: () => {
                toast?.show({
                    severity: 'success',
                    summary: 'Succesfully deleted',
                });
                listDataQuery.refetch();
            },
            onError: (err) => {
                toast?.show({
                    severity: 'error',
                    summary: 'Error when deleting',
                    detail: err.message,
                });
            },
        }
    );

    // ***************
    // useEffect
    // ***************

    // On model fetch update
    useEffect(() => {
        if (modelQuery.status === 'success') {
            const models = Object.entries(modelQuery.data).map((model) => {
                const modelIdLabel = model[1].fields.filter(
                    (field) => field.key === model[1].id_field
                );
                return {
                    label: model[0],
                    value: {
                        name: model[0],
                        id_label: modelIdLabel[0].label,
                        ...model[1],
                    },
                };
            });
            setModels(models);
        }
    }, [modelQuery.data, modelQuery.status]);

    // ***************
    // Functions
    // ***************

    const handleDelete = (oid: string) => {
        deleteMutate.mutate(oid);
    };

    // ***************
    // Render
    // ***************

    return (
        <div>
            <ObjectDisplayModal
                displayData={dialogRowData}
                visible={dialogRowDataVisible}
                onHide={() => setDialogRowDataVisible(false)}
            />
            {selectedApiSlug && selectedModel && (
                <>
                    <CreateDataDialog
                        apiSlug={selectedApiSlug}
                        header={
                            selectedModel
                                ? `Create ${selectedModel.name}`
                                : 'Create'
                        }
                        modelFormat={selectedModel}
                        visible={createDataDialogVisible}
                        onCreate={listDataQuery.refetch}
                        onHide={() => setCreateDataDialogVisible(false)}
                    />
                    {dialogRowData && (
                        <UpdateDataDialog
                            apiSlug={selectedApiSlug}
                            header={
                                selectedModel
                                    ? `Update ${selectedModel.name} (${dialogRowData?._id})`
                                    : 'Update'
                            }
                            modelFormat={selectedModel}
                            oidToUpdate={dialogRowData._id as string}
                            initialData={dialogRowData}
                            visible={updateDataDialogVisible}
                            onUpdate={listDataQuery.refetch}
                            onHide={() => setUpdateDataDialogVisible(false)}
                        />
                    )}
                </>
            )}
            <h1>Model Viewer</h1>

            <div style={{ display: 'flex' }}>
                <Dropdown
                    label='API'
                    value={selectedApiSlug}
                    onChange={(e) => setSelectedApiSlug(e.value)}
                    placeholder='Select an API'
                    error={
                        modelQuery.error ? modelQuery.error.message : undefined
                    }
                    options={apiModelQuery.data?.map((api) => ({
                        label: api.label,
                        value: api.slug,
                    }))}
                    style={{ marginRight: '16px' }}
                    loading={apiModelQuery.isLoading}
                />
                <Dropdown
                    label='Model'
                    value={selectedModel}
                    options={models}
                    onChange={(e) => setSelectedModel(e.value)}
                    placeholder='Select a model'
                    disabled={modelQuery.status !== 'success'}
                    style={{
                        display:
                            selectedApiSlug &&
                            modelQuery.status !== 'error' &&
                            modelQuery.status === 'success' &&
                            !modelQuery.error
                                ? 'inherit'
                                : 'none',
                    }}
                    labelStyle={{
                        display:
                            selectedApiSlug &&
                            modelQuery.status !== 'error' &&
                            modelQuery.status === 'success' &&
                            !modelQuery.error
                                ? 'inherit'
                                : 'none',
                    }}
                    loading={modelQuery.fetchStatus === 'fetching'}
                />
            </div>
            <div className='api-selection'>
                {selectedApiSlug && selectedModel && (
                    <>
                        <h1>{selectedModel.name}</h1>
                        <ExportGroup
                            apiSlug={selectedApiSlug}
                            model={selectedModel}
                            page={page}
                            amountPerPage={amountPerPage}
                            filters={processedFilters}
                        />
                        <div
                            style={{
                                display: 'inline-flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                marginBottom: '8px',
                            }}
                        >
                            <Button
                                label={`Create ${selectedModel.name}`}
                                icon='pi pi-plus'
                                disabled={listDataQuery.status !== 'success'}
                                onClick={() => setCreateDataDialogVisible(true)}
                            />
                        </div>
                    </>
                )}
            </div>
            <ListDataTableView
                currentModel={selectedModel}
                dataResult={listDataQuery.data}
                dataStatus={listDataQuery.status}
                dataFetchStatus={listDataQuery.fetchStatus}
                dataError={
                    listDataQuery.error
                        ? listDataQuery.error.message
                        : undefined
                }
                pageControl={{
                    page,
                    setPage,
                    amountPerPage,
                    setAmountPerPage,
                }}
                setProcessedFilters={setProcessedFilters}
                setSortBy={setSortBy}
                sortBy={sortBy}
                onRowSelection={(value) => {
                    setDialogRowData(value);
                    setDialogRowDataVisible(true);
                }}
                onEditClick={(value) => {
                    setDialogRowData(value);
                    setUpdateDataDialogVisible(true);
                }}
                onDelete={handleDelete}
                refetchData={listDataQuery.refetch}
            />
        </div>
    );
};

export default ModelViewer;
