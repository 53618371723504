import { InputText } from 'components/ethercity-primereact';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import isValidOid from 'utils/isValidOid';

const OidFilter = (options: ColumnFilterElementTemplateOptions) => {
    const isError = options.value && !isValidOid(options.value);

    return (
        <InputText
            style={{
                minWidth: '160px'
            }}
            placeholder='Search by ID'
            value={options.value === null ? '' : options.value}
            onChange={(e) => options.filterCallback(e.target.value)}
            error={isError ? 'Invalid OID' : null}
            maxLength={24}
        />
    );
};

export default OidFilter;
