import { Button } from 'primereact/button';
import { useState } from 'react';
import './style.css';

const TextContent: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [expand, setExpand] = useState(false);
    const content = children as string;

    const shortifyContent = (content: string) => {
        const leftPart = content.substring(0, 40);
        const rightPart = content.substring(content.length - 40);
        return (
            <div className='text-content-large'>
                <span style={{ maxWidth: '45ch' }}>
                    {leftPart + ' '}
                    <span style={{ color: 'var(--semaphore-green)' }}>
                        (...)
                    </span>
                    {' ' + rightPart}
                </span>
                <Button
                    style={{ padding: '8px' }}
                    icon='pi pi-plus'
                    onClick={() => setExpand(true)}
                    tooltip='Expand data'
                />
            </div>
        );
    };

    return (
        <>
            <div className='text-content'>
                {content.length > 100 && !expand
                    ? shortifyContent(content)
                    : content}
            </div>
        </>
    );
};

export default TextContent;
