import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { InputText, InputTextArea } from 'components/ethercity-primereact';
import { IModel } from '../..';
import { apiModelProxyUpdate } from 'services/ether/backoffice/api-model';

interface IRowDataProps extends DialogProps {
    apiSlug: string;
    modelFormat: IModel;
    oidToUpdate: string;
    initialData: { [key: string]: unknown } | undefined;
    accessToken?: string;
    onUpdate?(): void;
}

interface IRowData {
    [key: string]: { label: string; type: string | null; value: unknown };
}

const UpdateDataDialog: React.FC<IRowDataProps> = ({
    apiSlug,
    modelFormat,
    oidToUpdate,
    initialData,
    style,
    accessToken,
    onUpdate,
    ...rest
}) => {
    const toastRef = useRef<Toast>(null);
    const [rowFields, setRowFields] = useState<IRowData>({});

    const updateMutate = useMutation<
        unknown,
        Error,
        { [key: string]: unknown }
    >(
        (updateData) =>
            apiModelProxyUpdate(
                apiSlug,
                modelFormat.name,
                oidToUpdate,
                updateData
            ),
        {
            onSuccess: () => {
                toastRef.current?.show({
                    severity: 'success',
                    summary: `Updated ${oidToUpdate}`,
                });
                onUpdate && onUpdate();
                rest.onHide();
            },
            onError: (err) => {
                toastRef.current?.show({
                    severity: 'error',
                    summary: 'Error when updating',
                    detail: err.message,
                });
            },
        }
    );

    const handleUpdate = () => {
        const dataObject: { [key: string]: unknown } = {};
        Object.keys(rowFields).forEach((key) => {
            const type = rowFields[key].type;
            let value = rowFields[key].value;
            if (typeof value === 'string' && value === '') value = null;
            else {
                switch (type) {
                    case 'dict':
                        value = JSON.parse(value as string);
                        break;
                }
            }
            dataObject[key] = value;
        });
        updateMutate.mutate(dataObject);
    };

    const handleInitialValue = (value: unknown, type: string) => {
        const stringifyJson = (value: unknown) => {
            if (value == null) return '';
            return JSON.stringify(value);
        };

        switch (type) {
            case null:
                return stringifyJson(value);
            case 'dict':
                return stringifyJson(value);
            case 'list':
                return stringifyJson(value);
            case 'datetime':
                return new Date(value as string | Date);
            case 'bool':
                return typeof value === 'boolean' || value == null
                    ? value
                    : value === 'true'
                    ? true
                    : value != null
                    ? false
                    : null;
            case 'oid':
                return value;
            case 'str':
                return value == null ? '' : value;
            default:
                return value;
        }
    };

    const getFieldInput = (
        fieldName: string,
        type: string | null,
        value: unknown
    ): React.ReactElement => {
        const isJson = (value: string | undefined) => {
            if (!value) return true;
            try {
                JSON.parse(value);
            } catch (e) {
                return value === '';
            }
            return true;
        };

        const isOid = (value: string | undefined) => {
            if (!value) return true;
            const testCase = /[a-f0-9]{24}/;
            return testCase.test(value);
        };

        switch (type) {
            case null:
                return (
                    <InputTextArea
                        value={value as string}
                        onChange={(e) => {
                            setRowFields((old) => ({
                                ...old,
                                [fieldName]: {
                                    label: fieldName,
                                    type: type,
                                    value: e.target.value,
                                },
                            }));
                        }}
                    />
                );
            case 'dict':
                return (
                    <InputTextArea
                        value={value as string}
                        onChange={(e) => {
                            setRowFields((old) => ({
                                ...old,
                                [fieldName]: {
                                    label: fieldName,
                                    type: type,
                                    value: e.target.value,
                                },
                            }));
                        }}
                        validations={[
                            {
                                validate: isJson,
                                validationError: 'Must be a valid JSON object',
                            },
                        ]}
                    />
                );
            case 'list':
                return (
                    <InputTextArea
                        value={value as string}
                        onChange={(e) => {
                            setRowFields((old) => ({
                                ...old,
                                [fieldName]: {
                                    label: fieldName,
                                    type: type,
                                    value: e.target.value,
                                },
                            }));
                        }}
                    />
                );
            case 'datetime':
                return (
                    <Calendar
                        hideOnDateTimeSelect
                        value={value as Date}
                        onChange={(e) => {
                            setRowFields((old) => ({
                                ...old,
                                [fieldName]: {
                                    label: fieldName,
                                    type: type,
                                    value: e.target.value,
                                },
                            }));
                        }}
                        showTime
                        showSeconds
                    />
                );
            case 'bool':
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TriStateCheckbox
                            value={value as boolean}
                            onChange={(e) => {
                                setRowFields((old) => ({
                                    ...old,
                                    [fieldName]: {
                                        label: fieldName,
                                        type: type,
                                        value: e.target.value,
                                    },
                                }));
                            }}
                        />
                        <span style={{ marginLeft: '8px' }}>
                            <Badge
                                value={
                                    value
                                        ? 'true'
                                        : value == null
                                        ? 'null'
                                        : 'false'
                                }
                                severity={
                                    value
                                        ? 'success'
                                        : value != null
                                        ? 'danger'
                                        : undefined
                                }
                            />
                        </span>
                    </div>
                );
            case 'oid':
                return (
                    <InputText
                        value={value as string}
                        onChange={(e) => {
                            setRowFields((old) => ({
                                ...old,
                                [fieldName]: {
                                    label: fieldName,
                                    type: type,
                                    value: e.target.value,
                                },
                            }));
                        }}
                        validations={[
                            {
                                validate: isOid,
                                validationError: 'Invalid OID',
                            },
                        ]}
                    />
                );
            default:
                return (
                    <InputText
                        value={value as string}
                        onChange={(e) => {
                            setRowFields((old) => ({
                                ...old,
                                [fieldName]: {
                                    label: fieldName,
                                    type: type,
                                    value: e.target.value,
                                },
                            }));
                        }}
                    />
                );
        }
    };

    useEffect(() => {
        if (!modelFormat || !initialData) return;
        const rowFields: {
            [key: string]: { label: string; type: string; value: unknown };
        } = {};
        modelFormat.fields.forEach((field) => {
            if (field.key === modelFormat.id_field || !field.show) return;
            if (
                ['created_at', 'updated_at'].findIndex(
                    (x) => field.label === x
                ) !== -1
            )
                return;
            rowFields[field.label] = {
                label: field.label,
                type: field.type,
                value: handleInitialValue(initialData[field.label], field.type),
            };
        });
        setRowFields(rowFields);
    }, [modelFormat, initialData]);

    return (
        <>
            <Toast ref={toastRef} />
            <Dialog style={{ width: '80vw', ...style }} {...rest}>
                {modelFormat &&
                    Object.keys(rowFields).map((i) => {
                        return (
                            <div
                                key={rowFields[i].label}
                                style={{
                                    marginBottom: '16px',
                                    display: 'grid',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                            >
                                <span>{rowFields[i].label}</span>
                                {getFieldInput(
                                    rowFields[i].label,
                                    rowFields[i].type,
                                    rowFields[i].value
                                )}
                            </div>
                        );
                    })}
                <Button
                    label='Update'
                    onClick={handleUpdate}
                    loading={updateMutate.isLoading}
                />
            </Dialog>
        </>
    );
};

export default UpdateDataDialog;
