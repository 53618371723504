import { useMutation } from '@tanstack/react-query';
import { Dropdown, InputText } from 'components/ethercity-primereact';
import { useToast } from 'hooks/useToast';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import {
    UpdateConfigData,
    updateConfigModel,
} from 'services/ether/backoffice/config-model';
import { Ether } from 'types';
import { useConfigModelQuery } from '../..';
import { ListFieldsSelection } from '../CreateConfigModel';

const UpdateConfigModel = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const errorBoundary = useErrorBoundary();

    const configQuery = useConfigModelQuery();

    const [formData, setFormData] = useState<{
        name: string;
        configKey: string;
        modelTemplate: Ether.BackOffice.IConfigModel['model_template'];
        setup: {
            listFieldName: string;
            listFields: { name: string; type: Ether.BackOffice.FieldTypes }[];
        };
    }>({
        name: '',
        configKey: '',
        modelTemplate: 'generic',
        setup: {
            listFieldName: '',
            listFields: [
                {
                    name: '',
                    type: 'str',
                },
            ],
        },
    });

    const updateMutate = useMutation(
        async () => {
            const data: UpdateConfigData = {
                name: formData.name,
                model_template: formData.modelTemplate,
            };

            if (formData.modelTemplate === 'list') {
                const fields: { [key: string]: Ether.BackOffice.FieldTypes } =
                    {};
                formData.setup.listFields
                    .filter((item) => item.name !== '')
                    .forEach((item) => {
                        fields[item.name] = item.type;
                    });

                data.setup = {
                    list_fields: fields,
                };

                data.setup.list_field_name =
                    formData.setup.listFieldName === ''
                        ? null
                        : formData.setup.listFieldName;
            }

            return updateConfigModel(
                configQuery?.data?._id.toString() as string,
                data
            );
        },
        {
            onSuccess: () => {
                toast?.show({
                    severity: 'success',
                    summary: 'Succesfully updated config model',
                });
                navigate('../..');
            },
            onError: (err: Error) => {
                toast?.show({
                    severity: 'error',
                    summary: 'Error updating',
                    detail: err.message,
                });
            },
        }
    );

    const [jsonPreview, setJsonPreview] = useState<{
        [key: string]: any;
    } | null>(null);

    useEffect(() => {
        if (formData.configKey === '') {
            setJsonPreview(null);
            return;
        }
        const getBasicExample = (type: Ether.BackOffice.FieldTypes) => {
            switch (type) {
                case 'int':
                    return 0;
                case 'float':
                    return 0.5;
                case 'bool':
                    return true;
                case 'list':
                    return ['item1', 'item2'];
                case 'dict':
                    return { key: 'value' };
                case 'datetime':
                    return new Date();
                default:
                    return type;
            }
        };

        const newObj: any = {};
        newObj[formData.configKey] = {
            data: {},
            timestamp: new Date(),
        };
        if (formData.modelTemplate === 'list') {
            const exampleObj: any = {};

            formData.setup.listFields
                .filter((i) => i.name !== '')
                .forEach((i) => {
                    exampleObj[i.name] = getBasicExample(i.type);
                });

            if (formData.setup.listFieldName === '') {
                newObj[formData.configKey].data = [exampleObj];
            } else {
                newObj[formData.configKey].data[formData.setup.listFieldName] =
                    [exampleObj];
            }
        }
        setJsonPreview(newObj);
    }, [formData]);

    useEffect(() => {
        if (configQuery?.error) errorBoundary.showBoundary(configQuery.error);
        if (configQuery?.data) {
            setFormData({
                name: configQuery.data.name,
                configKey: configQuery.data.config_key,
                modelTemplate: configQuery.data.model_template,
                setup: {
                    listFieldName: configQuery.data.setup.list_field_name ?? '',
                    listFields: configQuery.data.setup.list_fields
                        ? Object.keys(configQuery.data.setup.list_fields)?.map(
                              (key) => ({
                                  name: key,
                                  type:
                                      configQuery.data?.setup.list_fields?.[
                                          key
                                      ] ?? 'str',
                              })
                          )
                        : [
                              {
                                  name: '',
                                  type: 'str',
                              },
                          ],
                },
            });
        }
    }, [configQuery?.data, configQuery?.error, errorBoundary]);

    if (configQuery?.isLoading) return <p>Loading data...</p>;

    return (
        <div>
            <h1>Update config</h1>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    alignItems: 'start',
                }}
            >
                <InputText
                    label='Name'
                    required
                    value={formData.name}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            name: e.target.value,
                        }))
                    }
                />
                <InputText
                    label='Config key'
                    required
                    value={formData.configKey}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            configKey: e.target.value,
                        }))
                    }
                    disabled
                />
                <Dropdown
                    label='Model type'
                    options={[
                        {
                            label: 'Generic',
                            value: 'generic',
                        },
                        {
                            label: 'List',
                            value: 'list',
                        },
                    ]}
                    value={formData.modelTemplate}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            modelTemplate: e.target.value,
                        }))
                    }
                    error={
                        configQuery?.data?.model_template !==
                        formData.modelTemplate
                            ? 'Change the template with caution, as this might not reflect on the actual saved data.'
                            : undefined
                    }
                />
                {formData.modelTemplate === 'list' && (
                    <>
                        <InputText
                            label='List field'
                            required={false}
                            tooltip="If not defined, the list will default to the config's data field."
                            value={formData.setup?.listFieldName}
                            onChange={(e) =>
                                setFormData((old) => {
                                    const newData = { ...old };
                                    newData.setup.listFieldName =
                                        e.target.value;
                                    return newData;
                                })
                            }
                            error={
                                (configQuery?.data?.setup.list_field_name ??
                                    '') !== formData.setup.listFieldName
                                    ? 'Change the field name with caution, as this might not reflect on the actual saved data.'
                                    : undefined
                            }
                        />
                        <ListFieldsSelection
                            fields={formData.setup.listFields}
                            onAddField={() =>
                                setFormData((old) => {
                                    const newData = { ...old.setup };
                                    const fields = [
                                        ...newData.listFields,
                                        {
                                            name: '',
                                            type: 'str' as Ether.BackOffice.FieldTypes,
                                        },
                                    ];
                                    newData.listFields = fields;
                                    return { ...old, setup: newData };
                                })
                            }
                            onRemoveField={(index) =>
                                setFormData((old) => {
                                    const newData = { ...old.setup };
                                    const fields = [...newData.listFields];
                                    fields.splice(index, 1);
                                    newData.listFields = fields;
                                    return { ...old, setup: newData };
                                })
                            }
                            handleFieldNameChange={(index, value) =>
                                setFormData((old) => {
                                    const newData = { ...old };
                                    newData.setup.listFields[index].name =
                                        value;
                                    return newData;
                                })
                            }
                            handleFieldTypeChange={(
                                index,
                                value: Ether.BackOffice.FieldTypes
                            ) =>
                                setFormData((old) => {
                                    const newData = { ...old };
                                    newData.setup.listFields[index].type =
                                        value;
                                    return newData;
                                })
                            }
                        />
                    </>
                )}
            </div>
            <h3>Config preview</h3>
            <pre
                style={{
                    fontSize: '12px',
                    lineHeight: '16px',
                }}
            >
                {jsonPreview != null
                    ? JSON.stringify(jsonPreview, null, 4)
                    : 'Nothing to preview'}
            </pre>
            <Button
                icon='pi pi-save'
                label='Update'
                onClick={() => updateMutate.mutate()}
                disabled={formData.name === ''}
            />
        </div>
    );
};

export default UpdateConfigModel;
