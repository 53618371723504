import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import Routing from 'routes/Routing';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            refetchOnWindowFocus : false,
        },
    },
});

function App() {
    return (
        <ErrorBoundary
            FallbackComponent={({ error }) => {
                return (
                    <div
                        style={{
                            height: '100vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <h2>The BackOffice App crashed</h2>
                        <p>{error.message}</p>
                    </div>
                );
            }}
        >
            <QueryClientProvider client={queryClient}>
                <Routing />
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

export default App;
