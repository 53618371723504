import axios from 'axios';
import { EJSON } from 'bson';
import { FilterMatchMode } from 'primereact/api';
import { DataTableFilterMetaData, DataTableOperatorFilterMetaData } from 'primereact/datatable';
import { Ether } from 'types';

export const extractTableFilterMeta = (data : DataTableFilterMetaData | DataTableOperatorFilterMetaData) => {
    if ('value' in data) return data;
    else return data.constraints[0];
}

export const handleFilterMatchMode = (
    key: string,
    value: string,
    mode: DataTableFilterMetaData['matchMode']
) => {
    if (value == null) return {};
    switch (mode) {
        case FilterMatchMode.STARTS_WITH:
            return { [key + '|regex']: '^' + value };
        case FilterMatchMode.CONTAINS:
            return { [key + '|regex']: value };
        case FilterMatchMode.NOT_CONTAINS:
            return { [key + '|regex']: `^(?!.*${value}).*$` };
        case FilterMatchMode.ENDS_WITH:
            return { [key + '|regex']: value + '$' };
        case FilterMatchMode.EQUALS:
            return { [key]: value };
        case FilterMatchMode.NOT_EQUALS:
            return { [key + '|ne']: value };
        case FilterMatchMode.LESS_THAN:
            return { [key + '|lt']: value };
        case FilterMatchMode.LESS_THAN_OR_EQUAL_TO:
            return { [key + '|lte']: value };
        case FilterMatchMode.GREATER_THAN:
            return { [key + '|gt']: value };
        case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO:
            return { [key + '|gte']: value };
        case FilterMatchMode.IN:
            return { [key + '|in']: value };

        // case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO: {
        //     const date = item[1].value as Date;
        //     apiFilters[item[0] + '|range'] = getDateRange(date);
        //     break;
        // }
        case FilterMatchMode.DATE_IS: {
            const tomorrow = new Date(value);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return {
                [key + '|range']: `${value},${tomorrow.toISOString()}`,
            };
        }
        case FilterMatchMode.DATE_IS_NOT: {
            const tomorrow = new Date(value);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return {
                [key + '|not,range']: `${value},${tomorrow.toISOString()}`,
            };
        }
        case FilterMatchMode.DATE_AFTER: {
            const tomorrow = new Date(value);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return { [key + '|gte']: tomorrow.toISOString() };
        }
        case FilterMatchMode.DATE_BEFORE: {
            return { [key + '|lt']: value };
        }
        // case 'dateBefore': {
        //     const date = item[1].value as Date;
        //     apiFilters[item[0] + '|lt'] = `${getDateISO(date)}`;
        //     break;
        // }
        // case 'dateAfter': {
        //     const date = item[1].value as Date;
        //     apiFilters[item[0] + '|gt'] = `${getDateISO(date)}T23:59:59.999999`;
        //     break;
        // }
        default:
            throw new Error('match mode not implemented');
    }
};

const instance = axios.create({
    timeout: 45000,
    baseURL : window.API_URL,
});

instance.interceptors.request.use(
    (req) => {
        return req;
    },
    (err) => {
        console.error(err);
    }
);

instance.interceptors.response.use(
    (res) => {
        res.data = EJSON.deserialize(res.data as any) as Ether.IApiResponse<any>;
        if (res.data.success != null && !res.data.success)
            throw new Error(res.data.error);
        return res;
    },
    (err) => {
        if (err.response) {
            if (typeof err.response?.data === 'string')
                throw new Error(err.response.data);
            else if (typeof err.response?.data?.error === 'string')
                throw new Error(err.response.data.error);
            throw new Error('Unknown server error');
        } else {
            if (err.message === 'Network Error')
                err.message = 'Failed to connect'
            throw err;
        }
    }
);

export default instance;
