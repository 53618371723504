import Navbar from 'components/Page/Navbar';
import ContentWrap from 'components/Page/ContentWrap';
import { Button } from 'primereact/button';
import { useAuth } from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';

const Login = () => {
    const { status, error, redirectUrl } = useAuth();

    if (status === 'loading') return <></>;
    if (status === 'success') return <Navigate to='/' />;

    return (
        <div>
            <Navbar />
            <ContentWrap>
                {error && <h2>Error: {error}</h2>}
                {window.EXTERNAL_URI_USERDIRECTORY ? (
                    <>
                        <h2>Login to the UserDirectory before proceeding</h2>
                        <a
                            href={`${
                                window.EXTERNAL_URI_USERDIRECTORY
                            }?callback=${encodeURIComponent(
                                redirectUrl ??
                                    window.location.href.replace('/login', '/')
                            )}`}
                        >
                            <Button label='Login' />
                        </a>
                    </>
                ) : (
                    <h2>Unable to login: UserDirectory URI was not defined</h2>
                )}
            </ContentWrap>
        </div>
    );
};

export default Login;
