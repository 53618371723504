import {
    TriStateCheckbox,
    Datepicker,
    InputNumber,
    InputText,
    InputTextArea,
    DateBadge,
} from 'components/ethercity-primereact';
import BoolFilter from 'components/filters/BoolFilter';
import DateFilter from 'components/filters/DateFilter';
import IntFilter from 'components/filters/IntFilter';
import StrFilter from 'components/filters/StrFilter';
import { Badge, BadgeProps } from 'primereact/badge';
import {
    ColumnFilterElementTemplateOptions,
    ColumnProps,
} from 'primereact/column';
import { ReactNode } from 'react';
import { Ether } from 'types';

export const parseValueForType = (
    value: any,
    type: Ether.BackOffice.FieldTypes
): any => {
    if (value == null || value === '') return null;
    switch (type) {
        case 'bool': {
            return Boolean(value);
        }
        case 'datetime': {
            return new Date(value);
        }
        case 'dict': {
            const parsedValue = JSON.parse(value);
            if (typeof parsedValue !== 'object' || Array.isArray(parsedValue))
                throw new Error(`parsing value ${value} did not yield dict`);
            return parsedValue;
        }
        case 'float': {
            return Number(value);
        }
        case 'int': {
            return Number(value);
        }
        case 'list': {
            const parsedValue = JSON.parse(value);
            if (typeof parsedValue !== 'object' || !Array.isArray(parsedValue))
                throw new Error(`parsing value ${value} did not yield list`);
            return parsedValue;
        }
        case 'str': {
            return value.toString();
        }
        default: {
            throw new Error(`unhandled type ${type}`);
        }
    }
};

export const getColumnPropsForType = (
    type: Ether.BackOffice.FieldTypes,
    field: string
): ColumnProps => {
    const props = {
        filter: true,
        showFilterOperator: false,
        showAddButton: false,
    } as ColumnProps;

    switch (type) {
        case 'bool': {
            props.filterElement = BoolFilter;
            props.dataType = 'boolean';
            props.filterMatchMode = 'equals';
            props.body = (data) => {
                const value = data[field];

                if (value == null) return '-';

                const badgeProps: BadgeProps = {};
                if (value === true) badgeProps.severity = 'success';
                else if (value === false) badgeProps.severity = 'danger';

                return <Badge value={value.toString()} {...badgeProps} />;
            };
            break;
        }
        case 'datetime': {
            props.filterElement = DateFilter;
            props.dataType = 'date';
            props.sortable = true;
            props.body = (data) => {
                const value = data[field];

                if (value == null) return '-';

                return <DateBadge value={value} />;
            };
            break;
        }
        case 'dict': {
            break;
        }
        case 'float': {
            props.dataType = 'numeric';
            props.sortable = true;
            break;
        }
        case 'int': {
            props.filterElement = (
                options: ColumnFilterElementTemplateOptions
            ) => {
                return IntFilter(options, 'Filter number');
            };
            props.dataType = 'numeric';
            props.sortable = true;
            break;
        }
        case 'list': {
            break;
        }
        case 'str': {
            props.filterElement = (
                options: ColumnFilterElementTemplateOptions
            ) => {
                return StrFilter(options, 'Filter text');
            };
            break;
        }
        default: {
            throw new Error('unhandled type');
        }
    }

    return props;
};

export const getInputForType = (
    type: Ether.BackOffice.FieldTypes,
    inputProps: {
        name: string;
        value: any;
        onChange: (value: any) => void;
        handleIsValid: (valid: boolean) => void;
    },
    options?: {
        label?: string;
    }
): ReactNode => {
    switch (type) {
        case 'bool': {
            return (
                <TriStateCheckbox
                    key={inputProps.name}
                    label={options?.label}
                    value={inputProps.value}
                    onChange={(e) => {
                        inputProps.onChange(e.target.value);
                        inputProps.handleIsValid(true);
                    }}
                />
            );
        }
        case 'datetime': {
            return (
                <Datepicker
                    key={inputProps.name}
                    type='datetime-local'
                    label={options?.label}
                    value={
                        typeof inputProps.value === 'string'
                            ? new Date(inputProps.value)
                            : inputProps.value
                    }
                    onChange={(date) => {
                        inputProps.onChange(date);
                        inputProps.handleIsValid(true);
                    }}
                />
            );
        }
        case 'dict': {
            return (
                <InputTextArea
                    key={inputProps.name}
                    label={options?.label}
                    value={inputProps.value}
                    onChange={(e) => {
                        inputProps.onChange(e.target.value);
                    }}
                    validationCallback={(isValid) =>
                        inputProps.handleIsValid(isValid)
                    }
                    validations={[
                        {
                            validate: (value) => {
                                if (!value) return true;
                                try {
                                    JSON.parse(value);
                                    return true;
                                } catch {
                                    return false;
                                }
                            },
                            validationError: 'Must be JSON',
                        },
                        {
                            validate: (value) => {
                                if (!value) return true;
                                const parsed = JSON.parse(value);
                                return !Array.isArray(parsed);
                            },
                            validationError: 'Must not be list',
                        },
                        {
                            validate : (value) => {
                                if (!value) return true;
                                const parsedValue = JSON.parse(value);
                                return typeof parsedValue === 'object'
                            },
                            validationError : 'Must be Key/Value JSON'
                        }
                    ]}
                />
            );
        }
        case 'float': {
            return (
                <InputNumber
                    key={inputProps.name}
                    maxFractionDigits={6}
                    label={options?.label}
                    value={inputProps.value}
                    onChange={(e) => {
                        inputProps.onChange(e.value);
                        inputProps.handleIsValid(true);
                    }}
                    useGrouping={false}
                />
            );
        }
        case 'int': {
            return (
                <InputNumber
                    key={inputProps.name}
                    minFractionDigits={0}
                    label={options?.label}
                    value={inputProps.value}
                    onChange={(e) => {
                        inputProps.onChange(e.value);
                        inputProps.handleIsValid(true);
                    }}
                    useGrouping={false}
                />
            );
        }
        case 'list': {
            return (
                <InputTextArea
                    key={inputProps.name}
                    label={options?.label}
                    value={inputProps.value}
                    onChange={(e) => 
                        inputProps.onChange(e.target.value)
                    }
                    validationCallback={(isValid) =>
                        inputProps.handleIsValid(isValid)
                    }
                    validations={[
                        {
                            validate: (value) => {
                                if (!value) return true;
                                try {
                                    JSON.parse(value);
                                    return true;
                                } catch {
                                    return false;
                                }
                            },
                            validationError: 'Must be JSON-list',
                        },
                        {
                            validate: (value) => {
                                if (!value) return true;
                                const parsed = JSON.parse(value);
                                return Array.isArray(parsed);
                            },
                            validationError: 'Must be list',
                        },
                    ]}
                />
            );
        }
        case 'str': {
            return (
                <InputText
                    key={inputProps.name}
                    label={options?.label}
                    value={inputProps.value}
                    onChange={(e) => {
                        inputProps.onChange(e.target.value);
                        inputProps.handleIsValid(true);
                    }}
                />
            );
        }
        default: {
            throw new Error('unhandled type');
        }
    }
};

export const getTypeInitialData = (type: Ether.BackOffice.FieldTypes) => {
    switch (type) {
        case 'bool':
            return null;
        case 'datetime':
            return null;
        case 'dict':
            // we parse json-like
            return '';
        case 'float':
            return null;
        case 'int':
            return null;
        case 'list':
            // also json like;
            return '';
        case 'str':
            return '';
        default:
            throw new Error('unhandled type');
    }
};
