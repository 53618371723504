import CacheControl from 'controller/cache/cacheController';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from 'services/ether';
import { getUserData } from 'services/ether/backoffice/me';
import { Ether } from 'types';

type FetchStatus = 'loading' | 'success' | 'unauthenticated';

interface IAuthData {
    user: Ether.IMe | null;
}

interface IUseAuth extends IAuthData {
    status: FetchStatus;
    error: string | null;
    signOut?(): void;
    permissions: Ether.BackOffice.PermissionsMapping | null;
    redirectUrl?: string;
}

const AuthContext = createContext<IUseAuth>({
    user: null,
    status: 'loading',
    error: null,
    permissions: null,
});

const getPermissions = (
    role: Ether.BackOffice.PermissionsMapping['name']
): Ether.BackOffice.PermissionsMapping => {
    const permissions: Ether.BackOffice.PermissionsMapping = {
        name: 'user',

        createConfigModel: false,
        updateConfigModel: false,
    };

    switch (role) {
        case 'admin':
            permissions.name = 'admin';
            Object.keys(permissions).forEach((k) => {
                if (k === 'name') return;
                (permissions as unknown as { [key: string]: boolean })[k] =
                    true;
            });
            break;
    }

    return permissions;
};

const AuthProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [error, setError] = useState<string | null>(null);
    const [authStatus, setAuthStatus] = useState<FetchStatus>('loading');
    const [redirectUrl, setRedirectUrl] = useState(window.location.href);

    const removeHash = () =>
        setSearchParams((old) => {
            const params = new URLSearchParams();
            old.forEach((value, key) => {
                if (key === 'hash') return;
                params.append(key, value);
            });
            return params;
        });

    const [localToken] = useState<string | null>(() => {
        const data = CacheControl.UserToken.get();
        return data ?? null;
    });
    const [authData, setAuthData] = useState<IAuthData>({
        user: null,
    });

    const role = authData.user?.is_superadmin ? 'admin' : 'user';
    const permissions = useMemo(
        () => (role ? getPermissions(role) : null),
        [role]
    );

    const signOut = (errorMessage?: string) => {
        removeHash();
        if (errorMessage) setError(errorMessage);
        setAuthStatus('unauthenticated');
        CacheControl.UserToken.delete();
        delete api.defaults.headers.common['access-token'];
        setRedirectUrl(window.location.href);
    };

    const checkToken = (token: string) => {
        api.defaults.headers.common['access-token'] = token;
        return new Promise<void>((resolve) => {
            getUserData()
                .then((meData) => {
                    CacheControl.UserToken.save(token);
                    setAuthData({
                        user: meData,
                    });
                    setAuthStatus('success');
                })
                .catch((err) => {
                    const response = err.response ? err.response : err;
                    if (response.message === 'authentication error') {
                        signOut('Invalid API token');
                    } else {
                        console.error(err);
                        signOut('Unexpected error - ' + err.message);
                    }
                })
                .finally(() => resolve());
        });
    };

    useEffect(() => {
        const hash = searchParams.get('hash');
        if (!hash) {
            if (localToken === null) {
                setAuthStatus('unauthenticated');
                return;
            }
            checkToken(localToken);
            return;
        }
        const formData = new FormData();
        formData.append('hash', hash);
        formData.append('app', 'backoffice');
        api.post(`${window.EXTERNAL_URI_USERDIRECTORY}/auth_hash/`, formData, {
            withCredentials: false,
        })
            .then(({ data }) => {
                if (data.app_data.token) {
                    checkToken(data.app_data.token).then(() => removeHash());
                } else {
                    signOut(
                        'No token received. Verify with an admin if you have a token for this application (backoffice) on the User Directory.'
                    );
                }
            })
            .catch(() => {
                signOut('Failed to validate hash on UserDirectory.');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localToken]);

    return (
        <AuthContext.Provider
            value={{
                ...authData,
                status: authStatus,
                error,
                signOut,
                redirectUrl,
                permissions,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = useContext(AuthContext);
    return context;
};

export { AuthProvider, useAuth };
