import { InputText } from 'components/ethercity-primereact';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';

const IntFilter = (
    options: ColumnFilterElementTemplateOptions,
    placeholder?: string
) => {
    return (
        <InputText
            style={{
                minWidth: '160px'
            }}
            placeholder={placeholder}
            value={options.value === null ? '' : options.value}
            onChange={(e) => options.filterCallback(e.target.value)}
            type='number'
        />
    );
};

export default IntFilter;
