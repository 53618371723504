import api from 'services/ether';
import { Ether } from 'types';

export const isTokenValid = (
    token: string,
    options?: {
        signal?: AbortSignal;
    }
): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
        api.get('me', {
            signal: options?.signal,
            headers: {
                'access-token': token,
            },
        })
            .then(() => resolve(true))
            .catch(() => resolve(false));
    });
};

export const getUserData = ({ signal }: { signal?: AbortSignal } = {}) => {
    return new Promise<Ether.IMe>((resolve, reject) => {
        api.get<{
            user: Ether.IMe;
        }>('me', {
            signal,
        })
            .then((res) => resolve(res.data.user))
            .catch((err) => {
                reject(err);
            });
    });
};
