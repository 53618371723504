import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';

const BoolFilter = (options: ColumnFilterElementTemplateOptions) => {
    return (
        <TriStateCheckbox
            value={options.value}
            onChange={(e) => options.filterCallback(e.value)}
        />
    );
};

export default BoolFilter;
