import { Ether } from 'types';
import api from 'services/ether';

export const listApiModels = (options?: { signal?: AbortSignal }) => {
    return new Promise<Ether.BackOffice.IApiModel[]>((resolve, reject) => {
        api.get<Ether.IApiResponse<Ether.BackOffice.IApiModel[]>>(
            `list-api-model`,
            {
                signal: options?.signal,
                params: {
                    limit: 99999,
                },
            }
        )
            .then((result) => {
                const apiModels = result.data.payload;
                resolve(apiModels);
            })
            .catch(reject);
    });
};

export const getModelsFromApi = (
    slug: string,
    options?: { signal?: AbortSignal }
) => {
    return new Promise<Ether.IModelsData>((resolve, reject) => {
        api.get<Ether.IApiResponse<Ether.IModelsData>>(
            `proxy-model-api/${slug}`,
            {
                signal: options?.signal,
            }
        )
            .then((result) => {
                const models = result.data.payload;
                resolve(models);
            })
            .catch(reject);
    });
};

export const apiModelProxyList = (
    slug: string,
    model: string,
    options?: {
        signal?: AbortSignal;
        params?: { [key: string]: string };
    }
) => {
    return new Promise<
        Ether.IApiResponse<
            {
                [key: string]: unknown;
            }[]
        >
    >((resolve, reject) => {
        api.get<
            Ether.IApiResponse<
                {
                    [key: string]: unknown;
                }[]
            >
        >(`proxy-list-api/${slug}/${model}`, {
            signal: options?.signal,
            params: options?.params,
        })
            .then((result) => resolve(result.data))
            .catch(reject);
    });
};

export const apiModelProxyCreate = (
    slug: string,
    model: string,
    data: any,
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<string>((resolve, reject) => {
        api.post<Ether.IApiResponse<string[]>>(
            `proxy-create-api/${slug}/${model}`,
            data,
            {
                signal: options?.signal,
            }
        )
            .then((result) => resolve(result.data.payload[0]))
            .catch(reject);
    });
};

export const apiModelProxyUpdate = (
    slug: string,
    model: string,
    oid : string,
    data: any,
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<boolean>((resolve, reject) => {
        api.post<Ether.IApiResponse<boolean[]>>(
            `proxy-update-api/${slug}/${model}/${oid}`,
            data,
            {
                signal: options?.signal,
            }
        )
            .then((result) => resolve(result.data.payload[0]))
            .catch(reject);
    });
};

export const apiModelProxyDelete = (
    slug: string,
    model: string,
    oid: string,
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<boolean>((resolve, reject) => {
        api.delete<Ether.IApiResponse<boolean[]>>(
            `proxy-delete-api/${slug}/${model}/${oid}`,
            {
                signal: options?.signal,
            }
        )
            .then((result) => resolve(result.data.payload[0]))
            .catch(reject);
    });
};
