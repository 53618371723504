import { Button } from "primereact/button";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <h2>404 - Page not found</h2>
            <Link to='/'>
                <Button label='Go home' />
            </Link>
        </div>
    );
};

export default NotFound;