import { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import './style.css';
import { Dropdown, InputNumber } from 'components/ethercity-primereact';
import { IModel } from '../..';
import { DataTableFilterMetaData } from 'primereact/datatable';
import { exportFile, ExportMode } from '../../utils/exportUtils';

import api from 'services/ether'

function kFormatter(num: number) {
    return Math.abs(num) > 999 ? (Math.abs(num) / 1000).toFixed(1) + 'k' : num;
}

export enum ExportFormat {
    CSV = 0,
    JSON = 1,
}

interface IExportGroup {
    apiSlug: string;
    model: IModel;
    page: number;
    amountPerPage: number;
    filters: {
        [key: string]: {
            value: unknown;
            filterType: DataTableFilterMetaData['matchMode'];
        };
    };
    disabled?: boolean;
}

const ExportGroup: React.FC<IExportGroup> = ({
    apiSlug,
    model,
    page,
    amountPerPage,
    filters,
    disabled,
}) => {
    const [exportFormat, setExportFormat] = useState<ExportFormat>(
        ExportFormat.CSV
    );
    const [exportLimit, setExportLimit] = useState(100);
    const [exportIsLoading, setExportIsLoading] = useState(false);
    const [exportDialogVisible, setExportDialogVisible] = useState(false);
    const [exportProgress, setExportProgress] = useState(0);

    const handleExport = (exportMode: ExportMode) => {
        setExportDialogVisible(false);
        exportFile(
            api.getUri() + `/proxy-list-api/${apiSlug}/${model.name}`,
            exportMode,
            exportFormat,
            {
                model: model,
                onDownloadStart: () => setExportIsLoading(true),
                onDownloadEnd: () => {
                    setExportIsLoading(false);
                    setExportProgress(0);
                },
                filters: filters,
            },
            {
                totalLimit: exportLimit,
                pageToExport: {
                    page: page,
                    limit: amountPerPage,
                },
            },
            setExportProgress
        );
    };

    return (
        <>
            <Button
                label={
                    !exportIsLoading
                        ? 'Export data'
                        : `Downloaded: ${kFormatter(exportProgress)}`
                }
                icon='pi pi-download'
                style={{ marginBottom: '8px', marginRight: '8px' }}
                disabled={disabled}
                loading={exportIsLoading}
                onClick={() => setExportDialogVisible(true)}
            />
            <Dialog
                header='Export content'
                className='dialog-export'
                visible={exportDialogVisible}
                onHide={() => setExportDialogVisible(false)}
            >
                <div className='dialog-export-content'>
                    <Dropdown
                        label='File format'
                        value={exportFormat}
                        options={[
                            { label: 'CSV', value: ExportFormat.CSV },
                            {
                                label: 'Multi-line JSON',
                                value: ExportFormat.JSON,
                            },
                        ]}
                        style={{ width: '100%' }}
                        onChange={(e) => setExportFormat(e.value)}
                    />
                    <Button
                        label='Export all data'
                        onClick={() => handleExport(ExportMode.EXPORT_ALL)}
                    />
                    <Button
                        label='Export current page'
                        onClick={() => handleExport(ExportMode.EXPORT_PAGE)}
                    />
                    <div className='dialog-limit-group'>
                        <Button
                            label='Export with limit'
                            onClick={() =>
                                handleExport(ExportMode.EXPORT_LIMIT)
                            }
                            style={{ width: '100%' }}
                        />
                        <label style={{ alignSelf: 'center' }}>Limit</label>
                        <InputNumber
                            format={false}
                            value={exportLimit}
                            onValueChange={(e) =>
                                e.value == null
                                    ? setExportLimit(1)
                                    : setExportLimit(e.value)
                            }
                            min={1}
                            size={2}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ExportGroup;
