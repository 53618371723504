import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { IRoute, routes } from 'routes';

const getTemplate = (item: MenuItem) => {
    const MenuText = <span className='p-menuitem-text'>{item.label}</span>;
    if (item.url) {
        return (
            <Link to={item.url} className='p-menuitem-link'>
                {MenuText}
            </Link>
        );
    }
    return MenuText;
};

const BreadCrumbContext = createContext<{
    replaceCrumbLabel: (label: string, newLabel: string) => void;
    setLabelByParam: (param: string, label: string) => void;
}>({
    replaceCrumbLabel: (a, b) => {},
    setLabelByParam: (a, b) => {},
});

const BreadCrumbProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const location = useLocation();
    const params = useParams();
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    const getActiveRoute = useCallback(
        (routes: IRoute[], previousMatched: string = ''): IRoute[] | undefined => {
            const getRouteName = (name: string) => {
                return name.startsWith(':')
                    ? params[name.split(':')[1]] ?? undefined
                    : name;
            };

            const path = location.pathname;

            const route = routes
                .filter((r) => r.path !== '@index')
                .find((r) => {
                    const name = getRouteName(r.path);
                    if (!name) return false;
                    const asd =
                        previousMatched +
                        (name.startsWith('/') || previousMatched === '/'
                            ? name
                            : '/' + name);
                    return path.startsWith(asd);
                });

            if (!route) return;

            let routing = [route];

            const name = getRouteName(route.path) as string;

            let children = route.children
                ? getActiveRoute(
                      route.children,
                      previousMatched +
                          (name.startsWith('/') || previousMatched === '/'
                              ? name
                              : '/' + name)
                  )
                : undefined;
            if (children) routing = [...routing, ...children];

            return routing.filter((a) => a != null && !a.breadcrumbOptions?.ignoreRender);
        },
        [location.pathname, params]
    );

    const replaceCrumbLabel = (label: string, newLabel: string) => {
        setMenuItems((old) => {
            const newItems = [...old];
            const index = newItems.findIndex((i) => i.label === label);
            if (index === -1) return old;
            newItems[index] = { ...newItems[index], label: newLabel };
            return newItems;
        });
    };

    const setLabelByParam = (param: string, label: string) => {
        const activeRoutes = getActiveRoute(routes);

        const index = activeRoutes?.findIndex(
            (r) => r.path === (param.startsWith(':') ? param : ':' + param)
        );

        if (!index || index === -1) return;

        setMenuItems((old) => {
            const newItems = [...old];
            newItems[index] = { ...newItems[index], label: label };
            return newItems;
        });
    };

    useEffect(() => {
        const activeRoutes = getActiveRoute(routes);

        let menuItems: MenuItem[] =
            activeRoutes?.map((i, index) => {
                const url = location.pathname
                    .split('/')
                    .slice(0, index + 2)
                    .join('/');

                return {
                    label: i.breadcrumbOptions?.label,
                    url: i.breadcrumbOptions?.validUrl ? url : undefined,
                };
            }) ?? [];

        menuItems = menuItems.map((i) => ({ ...i, template: getTemplate }));

        setMenuItems(menuItems);
    }, [location, getActiveRoute]);

    return (
        <>
            <BreadCrumbContext.Provider
                value={{
                    replaceCrumbLabel,
                    setLabelByParam,
                }}
            >
                <BreadCrumb model={menuItems} />
                {children}
            </BreadCrumbContext.Provider>
        </>
    );
};

const useBreadCrumb = () => {
    const context = useContext(BreadCrumbContext);
    return context;
};

export { BreadCrumbProvider, useBreadCrumb };
