import { ReactNode, useState } from 'react';
import { Button } from 'primereact/button';

import { ITemplateDisplay, useConfigDataQuery } from '../..';
import { Badge } from 'primereact/badge';
import { DateBadge, InputTextArea } from 'components/ethercity-primereact';
import { Dialog } from 'primereact/dialog';
import { useMutation } from '@tanstack/react-query';
import { useToast } from 'hooks/useToast';
import { genericTemplateUpdateConfigData } from 'services/ether/backoffice/manager';

export const LargeObjectView: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const [expand, setExpand] = useState(false);
    const content = JSON.stringify(children, null, 4);

    return (
        <div>
            {expand && (
                <Button
                    style={{ fontSize: '14px' }}
                    label='Hide'
                    icon='pi pi-minus'
                    onClick={() => setExpand(false)}
                />
            )}
            <pre
                style={{
                    fontSize: '18px',
                    lineHeight: '18px',
                    maxHeight: expand ? '100%' : '160px',
                    overflowY: expand ? 'auto' : 'hidden',
                }}
            >
                {content}
            </pre>
            {content.split('\n').length > 10 && !expand && (
                <Button
                    style={{ fontSize: '14px' }}
                    label='Show all'
                    icon='pi pi-plus'
                    onClick={() => setExpand(true)}
                />
            )}
        </div>
    );
};

const getFieldBody = (value: unknown): string | ReactNode => {
    if (value == null) return <Badge value='null' />;
    switch (typeof value) {
        case 'string':
            try {
                JSON.parse(value);
                return <LargeObjectView>{value as ReactNode}</LargeObjectView>;
            } catch {
                return value;
            }
        case 'object':
            if (value instanceof Date)
                return <DateBadge value={value as Date} />;
            return <LargeObjectView>{value as ReactNode}</LargeObjectView>;
        case 'boolean':
            return (
                <Badge
                    value={value ? 'true' : value == null ? 'null' : 'false'}
                    severity={
                        value ? 'success' : value != null ? 'danger' : undefined
                    }
                />
            );
        default:
            return value.toString();
    }
};

const DataUpdateModal = (props: {
    data: string;
    onDataChange: (value: string) => void;
    modalProps: {
        visible: boolean;
        onHide: () => void;
        header?: string;
    };
    onSubmit: (data: string) => void;
    submitButtonLabel?: string;
}) => {
    const [isValid, setIsValid] = useState(true);

    return (
        <Dialog {...props.modalProps}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                }}
            >
                <InputTextArea
                    style={{ width: '60vw', height: '40vh' }}
                    value={props.data}
                    onChange={(e) => props.onDataChange(e.target.value)}
                    validations={[{
                        validate : (value) => {
                            if (!value) return true;
                            try {
                                JSON.parse(value);
                                return true;
                            } catch {
                                return false;
                            }
                        },
                        validationError : 'Must be a valid JSON'
                    }]}
                    validationCallback={(v) => setIsValid(v)}
                />
                <Button
                    label={props.submitButtonLabel ?? 'Submit'}
                    onClick={() => props.onSubmit(props.data)}
                    disabled={!isValid}
                />
            </div>
        </Dialog>
    );
};

const GenericTemplate: React.FC<ITemplateDisplay> = ({ model, data }) => {
    const toast = useToast();
    const configDataQuery = useConfigDataQuery();

    const initItemData = (data : any) => {
        if (data == null) return '';
        return JSON.stringify(data, null, 4);
    }

    const [itemData, setItemData] = useState(() => initItemData(data));
    const [updateItemModalVisible, setUpdateItemModalVisible] = useState(false);

    const handleUpdateItemModalHide = () => {
        setUpdateItemModalVisible(false);
    }

    const updateItemMutation = useMutation<any, Error, string>(
        (data) => {
            let parsedData: any | null = null;
            if (data !== '') parsedData = JSON.parse(data);
            return genericTemplateUpdateConfigData(model.config_key, parsedData);
        },
        {
            onSuccess: (data) => {
                toast?.show({
                    severity: 'success',
                    summary: 'Succesfully updated data',
                });
                configDataQuery?.refetch();
                initItemData(data);
                handleUpdateItemModalHide();
            },
            onError: (err) =>
                toast?.show({
                    severity: 'error',
                    summary: 'Failed to update entry',
                    detail: err.message,
                }),
        }
    );

    return (
        <div>
            <DataUpdateModal
                data={itemData}
                onDataChange={(data) => setItemData(data)}
                onSubmit={(data) => updateItemMutation.mutate(data)}
                submitButtonLabel='Update'
                modalProps={{
                    visible: updateItemModalVisible,
                    onHide: handleUpdateItemModalHide,
                    header: 'Update data',
                }}
            />
            <Button
                label='Update'
                className='p-button-outlined'
                icon='pi pi-pencil'
                style={{ marginBottom: '8px' }}
                onClick={() => setUpdateItemModalVisible(true)}
            />
            <div
                style={{
                    backgroundColor: 'var(--very-darkish-blue)',
                    padding: '8px 24px',
                    borderRadius: '8px',
                }}
            >
                {getFieldBody(data)}
            </div>
        </div>
    );
};

export default GenericTemplate;
