import storageJSONSafeParse from 'utils/storageJSONSafeParse';

export const MAX_CACHE_TIME = 30 * 60 * 1000; // 30 minutes

enum STORAGE_KEYS {
    AUTH = 'backoffice:auth',
}

abstract class BaseCache<T> {
    key: string;
    constructor(key: string) {
        this.key = key;
        this.validate();
    }

    abstract validate(): void;
    protected abstract getAndHandleStorage(...args: any[]): T | null;

    get(...args: any[]): T | null {
        this.validate();
        return this.getAndHandleStorage(...args);
    }
    abstract save(...args: any[]): void;
    delete(): void {
        localStorage.removeItem(this.key);
    }
}

namespace CacheManager {
    export class UserToken extends BaseCache<string> {
        constructor() {
            super(STORAGE_KEYS.AUTH);
        }
        validate() {
            const auth = storageJSONSafeParse<string>(this.key);
            if (!auth) return;
            if (typeof auth !== 'string') {
                this.delete();
                return;
            }
        }
        getAndHandleStorage() {
            return storageJSONSafeParse<string>(this.key);
        }
        save(value: string) {
            localStorage.setItem(this.key, JSON.stringify(value));
        }
    }
}

class CacheControl {
    static UserToken = new CacheManager.UserToken();
}

export default CacheControl;
