import { useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import {
    DataTable,
    DataTableFilterMeta,
    DataTableFilterMetaData,
    DataTableOperatorFilterMetaData,
    DataTableSortMeta,
    DataTableStateEvent,
} from 'primereact/datatable';

import { FilterOperator } from 'primereact/api';
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';
import TextContent from '../TextContent';
import {
    DateBadge,
    OidBadge,
    Paginator,
} from 'components/ethercity-primereact';
import { ObjectId } from 'bson';
import { IModel } from '../..';
import { Ether } from 'types';

import OidFilter from 'components/filters/OidFilter';
import StrFilter from 'components/filters/StrFilter';
import DateFilter from 'components/filters/DateFilter';
import BoolFilter from 'components/filters/BoolFilter';
import IntFilter from 'components/filters/IntFilter';
import isValidOid from 'utils/isValidOid';
import { FetchStatus } from '@tanstack/react-query';

export interface IPageControl {
    page: number;
    amountPerPage: number;
    setPage(value: number): void;
    setAmountPerPage(value: number): void;
}

interface IListDataTableView {
    currentModel: IModel | null | undefined;
    dataStatus: 'loading' | 'error' | 'success';
    dataFetchStatus: FetchStatus;
    dataError: string | undefined;
    dataResult:
        | Ether.IApiResponse<
              {
                  [key: string]: unknown;
              }[]
          >
        | undefined;
    pageControl: IPageControl;
    sortBy:
        | {
              field: string;
              order: DataTableSortMeta['order'];
          }
        | undefined;
    setSortBy: React.Dispatch<
        React.SetStateAction<
            | {
                  field: string;
                  order: DataTableSortMeta['order'];
              }
            | undefined
        >
    >;
    setProcessedFilters: React.Dispatch<
        React.SetStateAction<{
            [key: string]: {
                value: unknown;
                filterType: DataTableFilterMetaData['matchMode'];
            };
        }>
    >;
    refetchData: () => void;
    onRowSelection(value: { [key: string]: unknown }): unknown;
    onDelete(oid: string): void;
    onEditClick(rowData: { [key: string]: unknown }): void;
}

const ListDataTableView: React.FC<IListDataTableView> = ({
    currentModel,
    pageControl,
    dataStatus,
    dataFetchStatus,
    dataError,
    dataResult,
    setProcessedFilters,
    sortBy,
    setSortBy,
    onRowSelection,
    onDelete,
    onEditClick,
    refetchData,
}) => {
    // Dialog
    const [dialogVisible, setDialogVisible] = useState(false);
    const [dialogContent, setDialogContent] = useState({
        header: '',
        content: '',
    });

    const [filterFields, setFilterFields] = useState<DataTableFilterMeta>({});

    useEffect(() => {
        if (currentModel?.fields) {
            const filterFields: DataTableFilterMeta = {
                [currentModel.id_field]: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
            };
            currentModel.fields.forEach((field) => {
                if (!field.show || field.key === currentModel.id_field) return;

                switch (field.type) {
                    case 'bool':
                        filterFields[field.key] = {
                            operator: FilterOperator.AND,
                            constraints: [{ value: null, matchMode: 'equals' }],
                        };
                        break;
                    case 'oid':
                        filterFields[field.key] = {
                            operator: FilterOperator.AND,
                            constraints: [{ value: null, matchMode: 'equals' }],
                        };
                        break;
                    case 'datetime':
                        filterFields[field.key] = {
                            operator: FilterOperator.AND,
                            constraints: [{ value: null, matchMode: 'dateIs' }],
                        };
                        break;
                    case 'int':
                        filterFields[field.key] = {
                            operator: FilterOperator.AND,
                            constraints: [{ value: null, matchMode: 'equals' }],
                        };
                        break;
                    default:
                        filterFields[field.key] = {
                            operator: FilterOperator.AND,
                            constraints: [
                                { value: null, matchMode: 'contains' },
                            ],
                        };
                }
            });
            setFilterFields(filterFields);
        }
    }, [currentModel]);

    const renderModelColumns = () => {
        if (!currentModel) return;

        const openDialogHandler = (header: string, content: unknown) => {
            setDialogVisible(true);
            setDialogContent({
                header,
                content: JSON.stringify(content, null, 4),
            });
        };

        const renderColumnObj = (
            rowData: { [key: string]: unknown },
            props: { field: string }
        ) => {
            const field = currentModel?.fields.find(
                (field) => field.key === props.field
            ) as Ether.IModelField;
            const isObject = (obj: unknown) => {
                return (
                    Object.prototype.toString.call(obj) === '[object Object]'
                );
            };

            const data = rowData[props.field];
            if (data == null) return <Badge value='null' />;
            if (data instanceof ObjectId)
                return <OidBadge value={data.toString()} />;
            if (isObject(data) || Array.isArray(data))
                return (
                    <div>
                        <Button
                            tooltip='Show more'
                            icon='pi pi-search'
                            className='p-button-outlined'
                            onClick={() => openDialogHandler(props.field, data)}
                        />
                    </div>
                );
            switch (field.type) {
                case 'datetime':
                    return <DateBadge value={data as Date} />;
                case 'bool':
                    return (
                        <Badge
                            value={data ? 'true' : 'false'}
                            severity='info'
                        />
                    );
                case 'oid':
                    return <OidBadge value={(data as ObjectId).toString()} />;
                default:
                    return <TextContent>{data as string}</TextContent>;
            }
        };

        if (currentModel?.fields) {
            return currentModel.fields.map((field) => {
                let sortable = false;
                const filterDetails: {
                    filter: boolean;
                    showFilterMenu: boolean;
                    filterElement?: (
                        options: ColumnFilterElementTemplateOptions
                    ) => JSX.Element;
                    dataType?: string;
                    showFilterOperator?: boolean;
                    showAddButton?: boolean;
                } = {
                    filter: false,
                    showFilterMenu: true,
                    showFilterOperator: false,
                    showAddButton: false,
                };
                if (!field.show || field.key === currentModel.id_field)
                    return null;
                if (
                    ['oid', 'datetime', 'int', 'str', 'bool'].find(
                        (element) => element === field.type
                    )
                )
                    sortable = true;

                switch (field.type) {
                    case 'oid':
                        filterDetails.filter = true;
                        filterDetails.showFilterMenu = false;
                        filterDetails.filterElement = OidFilter;
                        break;
                    case 'str':
                        filterDetails.filter = true;
                        filterDetails.filterElement = (
                            options: ColumnFilterElementTemplateOptions
                        ) => {
                            return StrFilter(options, `Filter ${field.label}`);
                        };
                        break;
                    case 'datetime':
                        filterDetails.filter = true;
                        filterDetails.filterElement = DateFilter;
                        filterDetails.dataType = 'date';
                        break;
                    case 'bool':
                        filterDetails.filter = true;
                        filterDetails.filterElement = BoolFilter;
                        filterDetails.dataType = 'boolean';
                        break;
                    case 'int':
                        filterDetails.filter = true;
                        filterDetails.filterElement = (
                            options: ColumnFilterElementTemplateOptions
                        ) => {
                            return IntFilter(options, `Filter ${field.label}`);
                        };
                        filterDetails.dataType = 'numeric';
                        break;
                }

                return (
                    <Column
                        key={field.key}
                        header={field.label}
                        field={field.key}
                        body={renderColumnObj}
                        sortable={sortable}
                        {...filterDetails}
                    />
                );
            });
        }
    };

    const SharedPaginator = (
        <Paginator
            page={pageControl.page}
            rows={pageControl.amountPerPage}
            onPageChange={(e) => {
                pageControl.setPage(e.page);
                pageControl.setAmountPerPage(e.rows);
            }}
            rowsPerPageOptions={[10, 50, 100]}
            disableNext={
                dataResult &&
                (dataResult.meta.payload_count === 0 ||
                    pageControl.amountPerPage > dataResult.meta.payload_count)
            }
            showRefresh
            onRefresh={refetchData}
            isRefreshing={dataFetchStatus === 'fetching'}
        />
    );

    const renderActionsButtons = (rowData: { [key: string]: unknown }) => {
        return (
            <div style={{ display: 'flex', gap: '8px' }}>
                <Button
                    icon='pi pi-search'
                    className='p-button-outlined'
                    tooltip='View'
                    onClick={() => onRowSelection(rowData)}
                />
                <Button
                    icon='pi pi-pencil'
                    className='p-button-outlined'
                    tooltip='Edit'
                    onClick={() => onEditClick(rowData)}
                />
                <Button
                    icon='pi pi-trash'
                    className='p-button-outlined'
                    onClick={() => confirmExclusion(rowData['_id'] as string)}
                    tooltip='Delete'
                />
            </div>
        );
    };

    const handleTableSort = (e: DataTableStateEvent) => {
        setSortBy({
            field: e.sortField,
            order: e.sortOrder,
        });
    };

    const handleTableFilter = (e: DataTableStateEvent) => {
        setFilterFields(e.filters);
        if (currentModel) {
            setProcessedFilters((oldFilterObj) => {
                const newFilterObj = {} as {
                    [key: string]: {
                        value: unknown;
                        filterType: DataTableFilterMetaData['matchMode'];
                    };
                };
                currentModel.fields.forEach((obj) => {
                    if (obj.key in e.filters) {
                        const filter = e.filters[obj.key] as
                            | DataTableOperatorFilterMetaData
                            | undefined;
                        if (filter && filter.constraints[0]) {
                            const { value, matchMode } = filter.constraints[0];
                            if (value == null || value === '') return;
                            else if (obj.type === 'oid' && !isValidOid(value))
                                return;
                            newFilterObj[obj.key] = {
                                value: value,
                                filterType: matchMode,
                            };
                        }
                    }
                });
                if (
                    JSON.stringify(oldFilterObj) ===
                    JSON.stringify(newFilterObj)
                )
                    return oldFilterObj;
                return newFilterObj;
            });
        }
    };

    const confirmExclusion = (oid: string) => {
        confirmDialog({
            message: `Are you sure you want to delete this record (${oid})?`,
            header: 'Delete confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            accept: () => onDelete(oid),
        });
    };

    if (!currentModel) return <></>;
    return (
        <div>
            <Dialog
                header={dialogContent.header}
                style={{ width: '60vw' }}
                visible={dialogVisible}
                onHide={() => setDialogVisible(false)}
            >
                <pre style={{ fontSize: '14px' }}>{dialogContent.content}</pre>
            </Dialog>
            <ConfirmDialog />
            {SharedPaginator}
            <DataTable
                value={dataResult?.payload}
                responsiveLayout='scroll'
                emptyMessage={dataStatus === 'error' ? dataError : 'No results'}
                loading={dataFetchStatus === 'fetching'}
                onSort={handleTableSort}
                sortField={sortBy?.field}
                sortOrder={sortBy?.order}
                onFilter={handleTableFilter}
                filters={filterFields}
                removableSort
                lazy
                style={{ marginBottom: '8px' }}
            >
                <Column
                    field={currentModel.id_field}
                    header={currentModel.id_label}
                    sortable
                    filter
                    filterElement={OidFilter}
                    showFilterMatchModes={false}
                    body={(
                        rowData: { [key: string]: unknown },
                        props: { field: string }
                    ) => {
                        return (
                            <OidBadge
                                value={(
                                    rowData[props.field] as ObjectId
                                ).toString()}
                            />
                        );
                    }}
                />
                <Column header='Actions' body={renderActionsButtons} />
                {currentModel && renderModelColumns()}
            </DataTable>
            {SharedPaginator}
        </div>
    );
};

export default ListDataTableView;
