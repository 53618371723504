import { Ether } from 'types';
import api from 'services/ether';

export const getConfigData = (
    configKey: string,
    options?: {
        signal?: AbortSignal;
    }
): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        api.get<Ether.IApiResponse<any>>(`get-config-data/${configKey}`, {
            signal: options?.signal,
        })
            .then((result) => {
                const data = result.data.payload;
                resolve(data);
            })
            .catch(reject);
    });
};

const baseManageConfigData = (
    configKey: string,
    payload: {
        operation: 'insert' | 'update' | 'delete';
        index?: number;
        data?: any;
        meta?: any;
    }
): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        api.post<Ether.IApiResponse<any>>(
            `manage-config-data/${configKey}`,
            payload
        )
            .then((result) => {
                const data = result.data.payload;
                resolve(data);
            })
            .catch(reject);
    });
};

export const genericTemplateUpdateConfigData = (
    configKey: string,
    data: any
): Promise<any> => {
    return baseManageConfigData(configKey, {
        operation: 'update',
        data,
    });
};

export const listTemplateInsertConfigData = (
    configKey: string,
    data: any
): Promise<any> => {
    return baseManageConfigData(configKey, {
        operation: 'insert',
        data,
    });
};

export const listTemplateUpdateIndexConfigData = (
    configKey: string,
    data: any,
    index: number
): Promise<any> => {
    return baseManageConfigData(configKey, {
        operation: 'update',
        index,
        data,
    });
};

export const listTemplateDeleteConfigData = (
    configKey: string,
    index: number
): Promise<any> => {
    return baseManageConfigData(configKey, {
        operation: 'delete',
        index,
    });
};

export const listTemplateUpdateConfigData = (
    configKey: string,
    data: { [key : string] : any }[],
): Promise<any> => {
    return baseManageConfigData(configKey, {
        operation: 'update',
        data,
    });
};
