import { Ether } from 'types';
import api, {
    extractTableFilterMeta,
    handleFilterMatchMode,
} from 'services/ether';
import { DataTableFilterMeta, DataTableSortMeta } from 'primereact/datatable';
import isValidOid from 'utils/isValidOid';

const handleDataTableFilterMeta = (filters?: DataTableFilterMeta) => {
    if (!filters) return {};
    let paramFilters: { [key: string]: string | number } = {};
    ['_id', 'group_id'].forEach((k) => {
        if (!filters[k]) return;
        const meta = extractTableFilterMeta(filters[k]);
        if (meta.value && isValidOid(meta.value)) paramFilters[k] = meta.value;
    });
    ['name', 'key'].forEach((k) => {
        if (!filters[k]) return;
        const meta = extractTableFilterMeta(filters[k]);
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(k, meta.value, meta.matchMode),
        };
    });
    return paramFilters;
};

export const listConfigModels = (options?: {
    signal?: AbortSignal;
    filters?: DataTableFilterMeta;
    sort?: DataTableSortMeta;
    limit?: number;
    offset?: number;
}): Promise<Ether.BackOffice.IConfigModel[]> => {
    return new Promise<Ether.BackOffice.IConfigModel[]>((resolve, reject) => {
        const extraParams = {
            ...handleDataTableFilterMeta(options?.filters),
        };
        if (options?.sort && options.sort.field)
            extraParams['order'] = `${options.sort.order === 1 ? '' : '-'}${
                options.sort.field
            }`;
        api.get<Ether.IApiResponse<Ether.BackOffice.IConfigModel[]>>(
            `list-config-model`,
            {
                signal: options?.signal,
                params: {
                    limit: options?.limit ?? 10,
                    offset: options?.offset ?? 0,
                    ...extraParams,
                },
            }
        )
            .then((result) => {
                const configModels = result.data.payload;
                resolve(configModels);
            })
            .catch(reject);
    });
};

export const getConfigModel = (
    id: string,
    options?: {
        signal?: AbortSignal;
    }
): Promise<Ether.BackOffice.IConfigModel> => {
    return new Promise<Ether.BackOffice.IConfigModel>((resolve, reject) => {
        api.get<Ether.IApiResponse<Ether.BackOffice.IConfigModel[]>>(
            `list-config-model`,
            {
                signal: options?.signal,
                params: {
                    _id: id,
                    limit: 1,
                },
            }
        )
            .then((result) => {
                const configModels = result.data.payload;
                if (configModels[0]) resolve(configModels[0]);
                else throw new Error('not found');
            })
            .catch(reject);
    });
};

export interface CreateConfigData {
    name: string;
    group_id: string;
    config_key: string;
    model_template: Ether.BackOffice.IConfigModel['model_template'];
    setup: {
        list_field_name?: string;
        list_fields?: { [key: string]: Ether.BackOffice.FieldTypes };
    };
}

export const createConfigModel = (
    data: CreateConfigData
): Promise<Ether.BackOffice.IConfigModel> => {
    return new Promise<Ether.BackOffice.IConfigModel>((resolve, reject) => {
        api.post<Ether.IApiResponse<Ether.BackOffice.IConfigModel>>(
            `create-config-model`,
            data
        )
            .then((result) => {
                const configModel = result.data.payload;
                resolve(configModel);
            })
            .catch(reject);
    });
};

export interface UpdateConfigData {
    name?: string;
    model_template?: Ether.BackOffice.IConfigModel['model_template'];
    setup?: {
        list_field_name?: string | null;
        list_fields?: { [key: string]: Ether.BackOffice.FieldTypes };
    };
}

export const updateConfigModel = (
    id: string,
    data: UpdateConfigData
): Promise<Ether.BackOffice.IConfigModel> => {
    return new Promise<Ether.BackOffice.IConfigModel>((resolve, reject) => {
        api.post<Ether.IApiResponse<Ether.BackOffice.IConfigModel>>(
            `update-config-model/${id}`,
            data
        )
            .then((result) => {
                const configModel = result.data.payload;
                resolve(configModel);
            })
            .catch(reject);
    });
};

export const deleteConfigModel = (id: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        api.delete<Ether.IApiResponse<boolean>>(`delete-config-model/${id}`)
            .then(() => resolve(true))
            .catch(reject);
    });
};
