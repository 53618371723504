import { useContext, PropsWithChildren, createContext } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';
import { getConfigModel } from 'services/ether/backoffice/config-model';
import { Ether } from 'types';
import { useBreadCrumb } from 'hooks/useBreadCrumb';

const ConfigModelContext = createContext<UseQueryResult<
    Ether.BackOffice.IConfigModel | null,
    Error
> | null>(null);

const ConfigModel: React.FC<PropsWithChildren> = ({ children }) => {
    const params = useParams<{
        configModelOid: string;
    }>();
    const breadCrumb = useBreadCrumb();

    const configModelQuery = useQuery<
        Ether.BackOffice.IConfigModel | null,
        Error
    >(
        ['get-config-model', params.configModelOid],
        async () => {
            if (!params.configModelOid) return null;
            return getConfigModel(params.configModelOid);
        },
        {
            onSettled: (data) => {
                if (!params.configModelOid || !data) return;
                breadCrumb?.setLabelByParam('configModelOid', data.name);
            },
        }
    );

    return (
        <ErrorBoundary
            FallbackComponent={({ error, resetErrorBoundary }) => {
                return (
                    <div>
                        <h2>Config failed</h2>
                        <p>{error.message}</p>
                        <Button
                            label='Retry'
                            onClick={resetErrorBoundary}
                            className='p-button-outlined'
                        />
                    </div>
                );
            }}
        >
            <ConfigModelContext.Provider value={configModelQuery}>
                {children}
            </ConfigModelContext.Provider>
        </ErrorBoundary>
    );
};

export const useConfigModelQuery = () => {
    const context = useContext(ConfigModelContext);
    return context;
};

export default ConfigModel;
