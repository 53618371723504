import { Outlet } from 'react-router-dom';

import Navbar from 'components/Page/Navbar';
import ContentWrap from 'components/Page/ContentWrap';
import { ToastProvider } from 'hooks/useToast';

const Main = () => {
    return (
        <div>
            <Navbar
                navigations={[
                    {
                        label: 'Home',
                        path: '/',
                    },
                    {
                        label: 'Configs',
                        path: '/config',
                    },
                    {
                        label: 'Model Viewer',
                        path: '/model-viewer',
                    },
                ]}
            />
            <ContentWrap>
                <ToastProvider>
                    <Outlet />
                </ToastProvider>
            </ContentWrap>
        </div>
    );
};

export default Main;
